export const budgetsPage = {
  title: 'Budgets',
  subtitle: 'Track and manage your spending limits',
  emptyState: {
    title: 'No budgets found',
    description: 'Create your first budget to start tracking your expenses'
  },
  createSection: {
    description: 'Create a budget to track your expenses and stay within your limits.'
  },
  addBudgetButton: 'Add Budget',
  budgetCard: {
    remaining: 'remaining',
    from: 'from',
    viewDetails: 'View Details'
  },
  wallets: 'Wallets',
  categories: 'Categories',
  allWallets: 'All wallets',
  allCategories: 'All categories',
  addNew: {
    title: 'Add New Budget',
    description: 'Set up a new budget to track your expenses'
  }
};
