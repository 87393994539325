export const acceptInvitationPage = {
  title: 'Daveti Kabul Et',
  description: 'Finansal takip çalışma alanına davet edildiniz',
  invitedBy: 'Davet Eden',
  permissions: {
    title: 'İzinler',
    viewer: 'Görüntüleyici - Finansal verileri görüntüleyebilir',
    editor: 'Düzenleyici - Finansal verileri görüntüleyebilir ve düzenleyebilir',
    admin: 'Yönetici - Çalışma alanı ayarlarına tam erişim'
  },
  actions: {
    accept: 'Daveti Kabul Et',
    decline: 'Daveti Reddet'
  },
  status: {
    pending: 'Davet Beklemede',
    accepted: 'Davet Kabul Edildi',
    declined: 'Davet Reddedildi'
  },
  errors: {
    invitationExpired: 'Davet süresi doldu',
    alreadyMember: 'Zaten bu çalışma alanının bir üyesisiniz',
    invitationNotFound: 'Davet bulunamadı'
  },
  workspace: {
    name: 'Çalışma Alanı Adı',
    members: 'Mevcut Üyeler'
  }
};
