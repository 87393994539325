import React, { useState } from 'react';
import { Settings2, Trash2, Plus } from 'lucide-react';
import * as Icons from 'lucide-react';
import { useCategories } from '../context/CategoryContext';
import { useLanguage } from '../context/LanguageContext';
import { useAuth } from '../context/AuthContext';
import { Category } from '../types';
import CategoryModal from '../components/categories/CategoryModal';
import { LucideIcon } from 'lucide-react';

const CategoriesPage = () => {
  const { categories, addCategory, updateCategory, deleteCategories } = useCategories();
  const { t } = useLanguage();
  const { user } = useAuth();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [editingCategory, setEditingCategory] = useState<Category | null>(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleAddCategory = (data: Omit<Category, 'id' | 'userId' | 'transactionCount' | 'wallets' | 'createdAt' | 'updatedAt'>) => {
    if (!user) return;
    
    const timestamp = new Date().toISOString();
    const categoryData = {
      ...data,
      userId: user.id,
      transactionCount: 0,
      wallets: [],
      createdAt: timestamp,
      updatedAt: timestamp
    };
    addCategory(categoryData);
    setIsAddModalOpen(false);
  };

  const handleUpdateCategory = (data: Omit<Category, 'id' | 'userId' | 'transactionCount' | 'wallets' | 'createdAt' | 'updatedAt'>) => {
    if (editingCategory) {
      updateCategory(editingCategory.id, data);
      setEditingCategory(null);
      setIsEditModalOpen(false);
    }
  };

  const handleDeleteSelected = () => {
    if (selectedCategories.length > 0) {
      deleteCategories(selectedCategories);
      setSelectedCategories([]);
    }
  };

  const toggleCategorySelection = (id: string) => {
    setSelectedCategories(current =>
      current.includes(id)
        ? current.filter(cId => cId !== id)
        : [...current, id]
    );
  };

  const renderCategoryList = (type: 'income' | 'expense') => {
    const filteredCategories = categories.filter(c => c.type === type);
    
    return (
      <div className="space-y-2">
        {filteredCategories.map(category => {
          const IconComponent = (Icons[category.icon as keyof typeof Icons] || Icons.Circle) as LucideIcon;

          return (
            <div
              key={category.id}
              className={`flex items-center p-3 ${
                selectedCategories.includes(category.id) 
                  ? 'bg-indigo-50 dark:bg-indigo-900/50' 
                  : 'hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
            >
              <input
                type="checkbox"
                checked={selectedCategories.includes(category.id)}
                onChange={() => toggleCategorySelection(category.id)}
                className="mr-3"
              />
              <IconComponent className={`h-5 w-5 text-${category.color}-500 dark:text-${category.color}-400 mr-3`} />
              <span className="flex-1 font-medium text-gray-900 dark:text-white">{category.name}</span>
              <span className="text-sm text-gray-500 dark:text-gray-400">
                {category.transactionCount} {t('pages.categoriesPage.details.transactions')}
                {category.transactionCount !== 1 ? 's' : ''} {t('pages.categoriesPage.details.transactionsIn')}{' '}
                {category.wallets.length} wallet{category.wallets.length !== 1 ? 's' : ''}
              </span>
              <div className="flex items-center ml-4">
                <button
                  onClick={() => {
                    setEditingCategory(category);
                    setIsEditModalOpen(true);
                  }}
                  className="p-1 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <Settings2 className="h-4 w-4" />
                </button>
                <button
                  onClick={() => deleteCategories([category.id])}
                  className="p-1 text-gray-400 hover:text-red-600 dark:hover:text-red-400 hover:bg-gray-100 dark:hover:bg-gray-700 ml-1"
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="p-8">
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">{t('pages.categoriesPage.title')}</h1>
        <p className="text-gray-600 dark:text-gray-400">{t('pages.categoriesPage.subtitle')}</p>
      </div>

      <div className="bg-white dark:bg-gray-800 shadow-sm border border-gray-200 dark:border-gray-700">
        <div className="p-6 border-b border-gray-200 dark:border-gray-700">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              {selectedCategories.length > 0 && (
                <button
                  onClick={handleDeleteSelected}
                  className="px-4 py-2 text-sm font-medium text-red-600 dark:text-red-400 bg-red-50 dark:bg-red-900/50 hover:bg-red-100 dark:hover:bg-red-900/70"
                >
                  {t('pages.categoriesPage.actions.deleteSelected')}
                </button>
              )}
            </div>
            <button
              onClick={() => setIsAddModalOpen(true)}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600"
            >
              <Plus className="h-4 w-4 mr-2" />
              {t('pages.categoriesPage.addCategoryButton')}
            </button>
          </div>
        </div>

        <div className="p-6">
          <div className="mb-6">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              {t('pages.categoriesPage.categoryTypes.income')}
            </h2>
            {renderCategoryList('income')}
          </div>

          <div>
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              {t('pages.categoriesPage.categoryTypes.expense')}
            </h2>
            {renderCategoryList('expense')}
          </div>
        </div>
      </div>

      <CategoryModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSubmit={handleAddCategory}
      />

      {editingCategory && (
        <CategoryModal
          isOpen={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false);
            setEditingCategory(null);
          }}
          onSubmit={handleUpdateCategory}
          initialData={editingCategory}
        />
      )}
    </div>
  );
};

export default CategoriesPage;
