import { collection, doc, writeBatch } from 'firebase/firestore';
import {
  addDoc,
  deleteDoc,
  getDocs,
  query,
  updateDoc,
  where,
  orderBy,
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { SubscriptionService } from '../types/subscription';
import { subscriptionList } from '../data/subscriptions';

// Helper function to get collection reference
const getPlatformsCollection = () => collection(db, 'subscription_platforms');

export const initializePlatforms = async (): Promise<void> => {
  const platformsCollection = getPlatformsCollection();
  // Check if platforms already exist
  const snapshot = await getDocs(platformsCollection);
  if (!snapshot.empty) {
    return; // Platforms already exist, don't initialize
  }

  // Collection is empty, initialize with default platforms
  const batch = writeBatch(db);
  
  for (const platform of subscriptionList) {
    const docRef = doc(platformsCollection);
    batch.set(docRef, {
      ...platform,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    });
  }

  await batch.commit();
};

export const createPlatform = async (data: Omit<SubscriptionService, 'id'>): Promise<string> => {
  const platformsCollection = getPlatformsCollection();
  const platformData = {
    ...data,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  };

  const docRef = await addDoc(platformsCollection, platformData);
  return docRef.id;
};

export const getAllPlatforms = async (): Promise<(SubscriptionService & { id: string })[]> => {
  const platformsCollection = getPlatformsCollection();
  const q = query(platformsCollection, orderBy('name', 'asc'));
  const querySnapshot = await getDocs(q);
  
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  } as SubscriptionService & { id: string }));
};

export const getPlatformsByCategory = async (
  category: string
): Promise<(SubscriptionService & { id: string })[]> => {
  const platformsCollection = getPlatformsCollection();
  const q = query(
    platformsCollection,
    where('category', '==', category),
    orderBy('name', 'asc')
  );
  
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  } as SubscriptionService & { id: string }));
};

export const updatePlatform = async (
  platformId: string,
  data: Partial<SubscriptionService>
): Promise<void> => {
  const platformsCollection = getPlatformsCollection();
  const platformRef = doc(platformsCollection, platformId);
  await updateDoc(platformRef, {
    ...data,
    updatedAt: new Date().toISOString()
  });
};

export const deletePlatform = async (platformId: string): Promise<void> => {
  const platformsCollection = getPlatformsCollection();
  await deleteDoc(doc(platformsCollection, platformId));
};
