import React, { useState } from 'react';
import { Package, Plus, Edit2, Trash2, Users, DollarSign, Check, X } from 'lucide-react';
import { SubscriptionPlan } from '../../types/subscription';
import AdminSubscriptionModal from '../../components/admin/AdminSubscriptionModal';

const AdminSubscriptions = () => {
  const [plans, setPlans] = useState<SubscriptionPlan[]>([
    {
      id: 'free',
      name: 'Free Plan',
      description: 'Basic features for personal use',
      price: 0,
      billingCycle: 'monthly',
      features: [
        '1 Wallet',
        '1 Budget',
        '1 Subscription tracking',
        'Basic reporting'
      ],
      limits: {
        wallets: 1,
        budgets: 1,
        subscriptions: 1,
        inviteMembers: false,
        allowExport: false,
        allowReports: false
      },
      isActive: true
    },
    {
      id: 'premium-monthly',
      name: 'Premium Plan',
      description: 'Advanced features for power users',
      price: 9.99,
      billingCycle: 'monthly',
      features: [
        'Unlimited Wallets',
        'Unlimited Budgets',
        'Unlimited Subscriptions',
        'Advanced reporting',
        'Data export',
        'Invite team members',
        'Priority support'
      ],
      limits: {
        wallets: -1, // unlimited
        budgets: -1,
        subscriptions: -1,
        inviteMembers: true,
        allowExport: true,
        allowReports: true
      },
      isActive: true
    },
    {
      id: 'premium-yearly',
      name: 'Premium Plan (Yearly)',
      description: 'Advanced features with yearly discount',
      price: 99.99,
      billingCycle: 'yearly',
      features: [
        'Unlimited Wallets',
        'Unlimited Budgets',
        'Unlimited Subscriptions',
        'Advanced reporting',
        'Data export',
        'Invite team members',
        'Priority support',
        '2 months free'
      ],
      limits: {
        wallets: -1,
        budgets: -1,
        subscriptions: -1,
        inviteMembers: true,
        allowExport: true,
        allowReports: true
      },
      isActive: true
    }
  ]);

  const [editingPlan, setEditingPlan] = useState<SubscriptionPlan | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const stats = {
    totalSubscribers: 1250,
    premiumSubscribers: 450,
    monthlyRecurringRevenue: 1350,
    yearlyRecurringRevenue: 11250
  };

  const handleUpdatePlan = (updatedPlan: SubscriptionPlan) => {
    setPlans(current =>
      current.map(plan =>
        plan.id === updatedPlan.id ? updatedPlan : plan
      )
    );
  };

  const handleAddPlan = (newPlan: SubscriptionPlan) => {
    setPlans(current => [...current, newPlan]);
  };

  const togglePlanStatus = (planId: string) => {
    setPlans(current =>
      current.map(plan =>
        plan.id === planId ? { ...plan, isActive: !plan.isActive } : plan
      )
    );
  };

  return (
    <div className="p-8">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Subscription Plans</h1>
          <p className="text-gray-600 dark:text-gray-400">Manage subscription plans and pricing</p>
        </div>
        <button
          onClick={() => {
            setEditingPlan(null);
            setIsModalOpen(true);
          }}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
        >
          <Plus className="h-4 w-4 mr-2" />
          Add Plan
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 p-6">
          <div className="flex items-center gap-3 mb-2">
            <div className="p-2 bg-indigo-100 dark:bg-indigo-900/50 rounded-lg">
              <Users className="h-5 w-5 text-indigo-600 dark:text-indigo-400" />
            </div>
            <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Total Subscribers</h3>
          </div>
          <p className="text-2xl font-bold text-gray-900 dark:text-white">{stats.totalSubscribers}</p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 p-6">
          <div className="flex items-center gap-3 mb-2">
            <div className="p-2 bg-green-100 dark:bg-green-900/50 rounded-lg">
              <Package className="h-5 w-5 text-green-600 dark:text-green-400" />
            </div>
            <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Premium Users</h3>
          </div>
          <p className="text-2xl font-bold text-gray-900 dark:text-white">{stats.premiumSubscribers}</p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 p-6">
          <div className="flex items-center gap-3 mb-2">
            <div className="p-2 bg-purple-100 dark:bg-purple-900/50 rounded-lg">
              <DollarSign className="h-5 w-5 text-purple-600 dark:text-purple-400" />
            </div>
            <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Monthly Revenue</h3>
          </div>
          <p className="text-2xl font-bold text-gray-900 dark:text-white">
            ${stats.monthlyRecurringRevenue}
          </p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 p-6">
          <div className="flex items-center gap-3 mb-2">
            <div className="p-2 bg-amber-100 dark:bg-amber-900/50 rounded-lg">
              <DollarSign className="h-5 w-5 text-amber-600 dark:text-amber-400" />
            </div>
            <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Annual Revenue</h3>
          </div>
          <p className="text-2xl font-bold text-gray-900 dark:text-white">
            ${stats.yearlyRecurringRevenue}
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {plans.map(plan => (
          <div
            key={plan.id}
            className={`bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 p-6 ${
              !plan.isActive ? 'opacity-60' : ''
            }`}
          >
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-indigo-100 dark:bg-indigo-900/50 rounded-lg">
                  <Package className="h-5 w-5 text-indigo-600 dark:text-indigo-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{plan.name}</h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400">{plan.description}</p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => {
                    setEditingPlan(plan);
                    setIsModalOpen(true);
                  }}
                  className="p-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <Edit2 className="h-4 w-4" />
                </button>
                <button
                  onClick={() => togglePlanStatus(plan.id)}
                  className={`p-2 rounded-full ${
                    plan.isActive
                      ? 'text-green-600 hover:text-green-700 hover:bg-green-100'
                      : 'text-red-600 hover:text-red-700 hover:bg-red-100'
                  }`}
                >
                  {plan.isActive ? <Check className="h-4 w-4" /> : <X className="h-4 w-4" />}
                </button>
              </div>
            </div>

            <div className="mb-4">
              <p className="text-3xl font-bold text-gray-900 dark:text-white">
                ${plan.price}
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  /{plan.billingCycle}
                </span>
              </p>
            </div>

            <ul className="space-y-3 mb-6">
              {plan.features.map((feature, index) => (
                <li key={index} className="flex items-center text-sm text-gray-600 dark:text-gray-300">
                  <Check className="h-4 w-4 text-green-500 mr-2" />
                  {feature}
                </li>
              ))}
            </ul>

            <div className="pt-4 border-t border-gray-100 dark:border-gray-700">
              <div className="flex items-center justify-between text-sm">
                <span className="text-gray-500 dark:text-gray-400">Status</span>
                <span className={`font-medium ${
                  plan.isActive
                    ? 'text-green-600 dark:text-green-400'
                    : 'text-red-600 dark:text-red-400'
                }`}>
                  {plan.isActive ? 'Active' : 'Inactive'}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      <AdminSubscriptionModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditingPlan(null);
        }}
        onSubmit={editingPlan ? handleUpdatePlan : handleAddPlan}
        initialData={editingPlan}
      />
    </div>
  );
};

export default AdminSubscriptions;