export const budgetsPage = {
  title: 'Bütçeler',
  subtitle: 'Harcama limitlerini takip et ve yönet',
  emptyState: {
    title: 'Bütçe bulunamadı',
    description: 'Harcamalarını takip etmek için ilk bütçeni oluştur'
  },
  createSection: {
    description: 'Harcamalarını takip etmek ve limitlerini kontrol etmek için bütçe oluştur.'
  },
  addBudgetButton: 'Bütçe Ekle',
  budgetCard: {
    remaining: 'kalan',
    from: 'toplam',
    viewDetails: 'Detayları Gör'
  },
  wallets: 'Cüzdanlar',
  categories: 'Kategoriler',
  allWallets: 'Tüm cüzdanlar',
  allCategories: 'Tüm kategoriler',
  addNew: {
    title: 'Yeni Bütçe Ekle',
    description: 'Harcamalarını takip etmek için yeni bir bütçe oluştur'
  }
};
