export const walletsPage = {
  title: 'Cüzdanlar',
  subtitle: 'Finansal hesaplarınızı yönetin ve bakiyeleri takip edin',
  noWallets: 'Hiç cüzdan bulunamadı',
  addWalletButton: 'Cüzdan Ekle',
  totalBalance: 'Toplam Bakiye',
  filters: {
    type: 'Cüzdan Türü',
    currency: 'Para Birimi'
  },
  walletTypes: {
    cash: 'Nakit',
    bank: 'Banka Hesabı',
    credit: 'Kredi Kartı',
    investment: 'Yatırım'
  },
  actions: {
    transfer: 'Transfer',
    deposit: 'Yatırma',
    withdraw: 'Çekme'
  },
  details: {
    transactions: 'işlem',
    viewDetails: 'Detayları Görüntüle',
    noTransactions: 'Hiç işlem bulunamadı'
  },
  balanceTypes: {
    current: 'Mevcut Bakiye',
    available: 'Kullanılabilir Bakiye'
  },
  quickActions: {
    addIncome: 'Gelir Ekle',
    addExpense: 'Gider Ekle'
  },
  stats: {
    income: 'Gelir',
    expenses: 'Gider'
  },
  addNew: {
    title: 'Yeni Cüzdan Ekle',
    description: 'Finanslarınızı yönetmek için yeni bir cüzdan oluşturun'
  }
};
