export const inviteMembersPage = {
  title: 'Invite Members',
  subtitle: 'Share your wallets with family, friends, or colleagues',
  form: {
    email: {
      label: 'Email address',
      placeholder: 'colleague@example.com'
    },
    accessLevel: {
      label: 'Access level',
      description: 'Editors can add, edit, and delete transactions. Viewers can only view transactions.',
      options: {
        editor: 'Can edit',
        viewer: 'View only'
      }
    },
    wallets: {
      label: 'Select wallets to share',
      selectAtLeastOne: 'Please select at least one wallet'
    },
    submit: {
      label: 'Send invitations',
      sending: 'Sending invitations...'
    }
  },
  messages: {
    success: 'Invitations sent successfully!',
    error: {
      selectWallet: 'Please select at least one wallet',
      sendFailed: 'Failed to send invitations'
    }
  },
  permissions: {
    viewer: 'Viewer',
    editor: 'Editor',
    admin: 'Admin'
  },
  validation: {
    emailRequired: 'Email is required',
    emailInvalid: 'Please enter a valid email address',
    maxInvitesReached: 'Maximum number of invites reached'
  },
  status: {
    pending: 'Pending',
    accepted: 'Accepted',
    declined: 'Declined'
  },
  actions: {
    resend: 'Resend Invitation',
    cancel: 'Cancel Invitation'
  },
  errors: {
    inviteFailed: 'Failed to send invitation',
    alreadyMember: 'This user is already a member',
    alreadyInvited: 'An invitation has already been sent to this email'
  }
};
