import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, ArrowUpRight, ArrowDownRight, PieChart, TrendingUp, Calendar, Edit2, Trash2 } from 'lucide-react';
import { useWallets } from '../context/WalletContext';
import { useTransactions } from '../context/TransactionContext';
import { useLanguage } from '../context/LanguageContext';
import { getTranslations } from '../locales/pages/walletDetailsPage';
import { Wallet } from '../types/wallet';
import { Transaction } from '../types/transaction';
import TransactionItem from '../components/transactions/TransactionItem';
import WalletModal from '../components/wallets/WalletModal';

const WalletDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { language } = useLanguage();
  const translations = getTranslations(language);
  const { wallets, editWallet, deleteWallet } = useWallets();
  const { transactions } = useTransactions();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0],
    end: new Date().toISOString().split('T')[0]
  });

  const wallet = wallets.find(w => w.id === id);
  
  if (!wallet) {
    return (
      <div className="p-8">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900">{translations.errors.notFound.title}</h2>
          <button
            onClick={() => navigate('/wallets')}
            className="mt-4 text-indigo-600 hover:text-indigo-700"
          >
            {translations.errors.notFound.action}
          </button>
        </div>
      </div>
    );
  }

  const setQuickDateRange = (range: 'today' | 'week' | 'month') => {
    const end = new Date();
    let start = new Date();

    switch (range) {
      case 'today':
        start = new Date();
        break;
      case 'week':
        start = new Date();
        start.setDate(start.getDate() - 7);
        break;
      case 'month':
        start = new Date();
        start.setMonth(start.getMonth() - 1);
        break;
    }

    setDateRange({
      start: start.toISOString().split('T')[0],
      end: end.toISOString().split('T')[0]
    });
  };

  const walletTransactions = transactions
    .filter(t => t.walletId === wallet.id)
    .filter(t => {
      const transactionDate = new Date(t.date);
      const startDate = new Date(dateRange.start);
      const endDate = new Date(dateRange.end);
      return transactionDate >= startDate && transactionDate <= endDate;
    })
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const stats = {
    income: walletTransactions.filter(t => t.amount > 0).reduce((sum, t) => sum + t.amount, 0),
    expenses: Math.abs(walletTransactions.filter(t => t.amount < 0).reduce((sum, t) => sum + t.amount, 0)),
    get balance() { return this.income - this.expenses; },
    get savingsRate() { return this.income > 0 ? ((this.income - this.expenses) / this.income) * 100 : 0; }
  };

  const handleEdit = (data: Omit<Wallet, 'id'>) => {
    editWallet(wallet.id, data);
    setIsEditModalOpen(false);
  };

  const handleDelete = () => {
    if (window.confirm(translations.confirmations.deleteWallet)) {
      deleteWallet(wallet.id);
      navigate('/wallets');
    }
  };

  return (
    <div className="p-8">
      {/* Header */}
      <div className="mb-8">
        <button
          onClick={() => navigate('/wallets')}
          className="flex items-center text-gray-600 hover:text-gray-900 mb-4"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          {translations.navigation.backToWallets}
        </button>
        
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">{wallet.name}</h1>
            <p className="text-gray-600">{translations.header.subtitle}</p>
          </div>
          <div className="flex items-center gap-3">
            <button
              onClick={() => setIsEditModalOpen(true)}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              <Edit2 className="h-4 w-4 mr-2" />
              {translations.header.buttons.edit}
            </button>
            <button
              onClick={handleDelete}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-red-600 bg-red-50 rounded-lg hover:bg-red-100"
            >
              <Trash2 className="h-4 w-4 mr-2" />
              {translations.header.buttons.delete}
            </button>
          </div>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <div className={`p-3 rounded-lg bg-${wallet.color || 'indigo'}-100 inline-block mb-4`}>
            <TrendingUp className={`h-6 w-6 text-${wallet.color || 'indigo'}-600`} />
          </div>
          <p className="text-sm text-gray-500 mb-1">{translations.stats.currentBalance}</p>
          <p className="text-2xl font-bold text-gray-900">
            {wallet.balance.toLocaleString('en-US', {
              style: 'currency',
              currency: wallet.currency
            })}
          </p>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <div className="p-3 rounded-lg bg-green-100 inline-block mb-4">
            <ArrowUpRight className="h-6 w-6 text-green-600" />
          </div>
          <p className="text-sm text-gray-500 mb-1">{translations.stats.totalIncome}</p>
          <p className="text-2xl font-bold text-green-600">
            {stats.income.toLocaleString('en-US', {
              style: 'currency',
              currency: wallet.currency
            })}
          </p>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <div className="p-3 rounded-lg bg-red-100 inline-block mb-4">
            <ArrowDownRight className="h-6 w-6 text-red-600" />
          </div>
          <p className="text-sm text-gray-500 mb-1">{translations.stats.totalExpenses}</p>
          <p className="text-2xl font-bold text-red-600">
            {stats.expenses.toLocaleString('en-US', {
              style: 'currency',
              currency: wallet.currency
            })}
          </p>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <div className="p-3 rounded-lg bg-indigo-100 inline-block mb-4">
            <PieChart className="h-6 w-6 text-indigo-600" />
          </div>
          <p className="text-sm text-gray-500 mb-1">{translations.stats.savingsRate}</p>
          <p className="text-2xl font-bold text-indigo-600">
            {stats.savingsRate.toFixed(1)}%
          </p>
        </div>
      </div>

      {/* Transactions Section */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100">
        <div className="p-6 border-b border-gray-100">
          <div className="flex items-center justify-between flex-wrap gap-4">
            <h2 className="text-lg font-semibold text-gray-900">{translations.transactions.title}</h2>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setQuickDateRange('today')}
                  className="px-3 py-1 text-sm font-medium rounded-lg hover:bg-gray-100"
                >
                  {translations.transactions.dateRanges.today}
                </button>
                <button
                  onClick={() => setQuickDateRange('week')}
                  className="px-3 py-1 text-sm font-medium rounded-lg hover:bg-gray-100"
                >
                  {translations.transactions.dateRanges.thisWeek}
                </button>
                <button
                  onClick={() => setQuickDateRange('month')}
                  className="px-3 py-1 text-sm font-medium rounded-lg hover:bg-gray-100"
                >
                  {translations.transactions.dateRanges.thisMonth}
                </button>
              </div>
              <div className="flex items-center gap-2 px-4 py-2 border border-gray-200 rounded-lg bg-gray-50">
                <Calendar className="h-4 w-4 text-gray-400" />
                <input
                  type="date"
                  value={dateRange.start}
                  onChange={(e) => setDateRange(prev => ({ ...prev, start: e.target.value }))}
                  className="text-sm border-none bg-transparent focus:ring-0"
                />
                <span className="text-gray-400">{translations.transactions.datePicker.to}</span>
                <input
                  type="date"
                  value={dateRange.end}
                  onChange={(e) => setDateRange(prev => ({ ...prev, end: e.target.value }))}
                  className="text-sm border-none bg-transparent focus:ring-0"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="divide-y divide-gray-100">
          {walletTransactions.length === 0 ? (
            <div className="p-6 text-center text-gray-500">
              {translations.transactions.empty}
            </div>
          ) : (
            walletTransactions.map((transaction: Transaction) => (
              <TransactionItem key={transaction.id} {...transaction} />
            ))
          )}
        </div>
      </div>

      <WalletModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onSubmit={handleEdit}
        initialData={wallet}
      />
    </div>
  );
};

export default WalletDetailsPage;
