import React, { useState, useRef, useEffect } from 'react';
import { Camera, Trash2, User } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
import { updateUserProfile, changePassword } from '../services/authOperations';
import DeleteAccountModal from '../components/settings/DeleteAccountModal';
import { getTranslations as getDeleteAccountTranslations, type DeleteAccountTranslations } from '../locales/components/deleteAccountModal';

const AccountPage: React.FC = () => {
  const { user } = useAuth();
  const { t, language, setLanguage } = useLanguage();
  const [isUploading, setIsUploading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [profileError, setProfileError] = useState<string | null>(null);
  const [profileSuccess, setProfileSuccess] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [passwordSuccess, setPasswordSuccess] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<'en' | 'tr'>(user?.settings?.language || language);
  const [selectedCurrency, setSelectedCurrency] = useState(user?.settings?.currency || 'usd');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const currentPasswordRef = useRef<HTMLInputElement>(null);
  const newPasswordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const deleteAccountTranslations: DeleteAccountTranslations = getDeleteAccountTranslations(language);

  // Update local state when user settings change
  useEffect(() => {
    if (user?.settings) {
      setSelectedLanguage(user.settings.language);
      setSelectedCurrency(user.settings.currency);
    }
  }, [user?.settings]);

  // Clear messages when component unmounts
  useEffect(() => {
    return () => {
      setProfileError(null);
      setProfileSuccess(null);
      setPasswordError(null);
      setPasswordSuccess(null);
    };
  }, []);

  const handlePhotoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!user) return;

    try {
      setIsUploading(true);
      await updateUserProfile(user.id, {}, file);
    } catch (err: unknown) {
      console.error('Error uploading profile photo:', err);
    } finally {
      setIsUploading(false);
    }
  };

  const handleRemovePhoto = async () => {
    if (!user) return;

    try {
      setIsUploading(true);
      await updateUserProfile(user.id, { avatar: null });
    } catch (err: unknown) {
      console.error('Error removing profile photo:', err);
    } finally {
      setIsUploading(false);
    }
  };

  const clearPasswordFields = () => {
    if (currentPasswordRef.current) currentPasswordRef.current.value = '';
    if (newPasswordRef.current) newPasswordRef.current.value = '';
    if (confirmPasswordRef.current) confirmPasswordRef.current.value = '';
  };

  const handlePasswordChange = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    const currentPassword = currentPasswordRef.current?.value || '';
    const newPassword = newPasswordRef.current?.value || '';
    const confirmPassword = confirmPasswordRef.current?.value || '';

    if (!currentPassword || !newPassword || !confirmPassword) {
      setPasswordError(t('pages.accountPage.errors.currentPasswordRequired'));
      return;
    }

    if (newPassword !== confirmPassword) {
      setPasswordError(t('pages.accountPage.errors.passwordMismatch'));
      return;
    }

    try {
      setIsChangingPassword(true);
      setPasswordError(null);
      setPasswordSuccess(null);
      await changePassword(currentPassword, newPassword);
      setPasswordSuccess(t('pages.accountPage.success.passwordChanged'));
      clearPasswordFields();
    } catch (err: unknown) {
      console.error('Error changing password:', err);
      setPasswordError(err instanceof Error ? err.message : 'Failed to change password');
    } finally {
      setIsChangingPassword(false);
    }
  };

  const handleProfileUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;
    
    try {
      setIsUpdating(true);
      setProfileError(null);
      
      // Update user profile with settings
      await updateUserProfile(user.id, {
        settings: {
          language: selectedLanguage,
          currency: selectedCurrency.toLowerCase(),
          theme: user.settings?.theme || 'light',
          notifications: user.settings?.notifications ?? true
        }
      });

      // Update language context after successful update
      setLanguage(selectedLanguage);
      setProfileSuccess(t('pages.accountPage.success.profileUpdated'));
    } catch (err: unknown) {
      console.error('Error updating profile:', err);
      setProfileError(err instanceof Error ? err.message : 'Failed to update profile');
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="p-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
          {t('pages.accountPage.title')}
        </h1>

        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700">
          {/* Profile Photo Section */}
          <section className="p-6 border-b border-gray-100 dark:border-gray-700">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-1">
              {t('pages.accountPage.personalInfo')}
            </h2>

            <div className="flex items-center gap-6">
              <div className="relative">
                {user?.avatar ? (
                  <img
                    src={user.avatar}
                    alt={user.name}
                    className="w-24 h-24 rounded-full object-cover"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(user.name)}&background=random`;
                    }}
                  />
                ) : (
                  <div className="w-24 h-24 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
                    <User className="h-12 w-12 text-gray-500 dark:text-gray-400" />
                  </div>
                )}

                {isUploading && (
                  <div className="absolute inset-0 bg-black bg-opacity-50 rounded-full flex items-center justify-center">
                    <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
                  </div>
                )}
              </div>

              <div className="flex gap-4">
                <button
                  type="button"
                  onClick={() => fileInputRef.current?.click()}
                  disabled={isUploading}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <Camera className="h-4 w-4 mr-2" />
                  {t('pages.accountPage.photo.upload')}
                </button>

                {user?.avatar && (
                  <button
                    type="button"
                    onClick={handleRemovePhoto}
                    disabled={isUploading}
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-red-600 bg-red-100 rounded-lg hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <Trash2 className="h-4 w-4 mr-2" />
                    {t('pages.accountPage.photo.remove')}
                  </button>
                )}

                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoUpload}
                  className="hidden"
                />
              </div>
            </div>
          </section>

          {/* Profile Information Form */}
          <form onSubmit={handleProfileUpdate} className="p-6 border-b border-gray-100 dark:border-gray-700">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
              {t('pages.accountPage.personalInfo')}
            </h2>

            {profileSuccess && (
              <div className="mb-4 p-3 bg-green-100 text-green-700 rounded-lg">
                {profileSuccess}
              </div>
            )}

            {profileError && (
              <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg">
                {profileError}
              </div>
            )}

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('pages.accountPage.name')}
                </label>
                <input
                  type="text"
                  value={user?.name || ''}
                  readOnly
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('pages.accountPage.email')}
                </label>
                <input
                  type="text"
                  value={user?.email || ''}
                  readOnly
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('pages.accountPage.language')}
                </label>
                <select
                  name="language"
                  value={selectedLanguage}
                  onChange={(e) => setSelectedLanguage(e.target.value as 'en' | 'tr')}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                >
                  <option value="en">{t('pages.accountPage.languageOptions.english')}</option>
                  <option value="tr">{t('pages.accountPage.languageOptions.turkish')}</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('pages.accountPage.currency')}
                </label>
                <select
                  name="currency"
                  value={selectedCurrency}
                  onChange={(e) => setSelectedCurrency(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                >
                  <option value="usd">{t('pages.accountPage.currencyOptions.usd')}</option>
                  <option value="eur">{t('pages.accountPage.currencyOptions.eur')}</option>
                  <option value="gbp">{t('pages.accountPage.currencyOptions.gbp')}</option>
                  <option value="try">{t('pages.accountPage.currencyOptions.try')}</option>
                  <option value="jpy">{t('pages.accountPage.currencyOptions.jpy')}</option>
                  <option value="cny">{t('pages.accountPage.currencyOptions.cny')}</option>
                </select>
              </div>

              <div className="pt-4">
                <button
                  type="submit"
                  disabled={isUpdating}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isUpdating ? 'Saving...' : t('pages.accountPage.updateButton')}
                </button>
              </div>
            </div>
          </form>

          {/* Password Change Form */}
          <form onSubmit={handlePasswordChange} className="p-6 border-b border-gray-100 dark:border-gray-700">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
              {t('pages.accountPage.changePassword')}
            </h2>

            {passwordError && (
              <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg">
                {passwordError}
              </div>
            )}

            {passwordSuccess && (
              <div className="mb-4 p-3 bg-green-100 text-green-700 rounded-lg">
                {passwordSuccess}
              </div>
            )}

            <div className="space-y-4">
              <div>
                {/* Hidden username field for current password */}
                <input
                  type="text"
                  defaultValue={user?.email || ''}
                  autoComplete="username"
                  aria-hidden="true"
                  className="hidden"
                />
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('pages.accountPage.currentPassword')}
                </label>
                <input
                  ref={currentPasswordRef}
                  type="password"
                  autoComplete="current-password"
                  aria-label={t('pages.accountPage.currentPassword')}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                  required
                />
              </div>

              <div>
                {/* Hidden username field for new password */}
                <input
                  type="text"
                  defaultValue={user?.email || ''}
                  autoComplete="username"
                  aria-hidden="true"
                  className="hidden"
                />
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('pages.accountPage.newPassword')}
                </label>
                <input
                  ref={newPasswordRef}
                  type="password"
                  autoComplete="new-password"
                  aria-label={t('pages.accountPage.newPassword')}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                  required
                />
              </div>

              <div>
                {/* Hidden username field for confirm password */}
                <input
                  type="text"
                  defaultValue={user?.email || ''}
                  autoComplete="username"
                  aria-hidden="true"
                  className="hidden"
                />
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('pages.accountPage.confirmNewPassword')}
                </label>
                <input
                  ref={confirmPasswordRef}
                  type="password"
                  autoComplete="new-password"
                  aria-label={t('pages.accountPage.confirmNewPassword')}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                  required
                />
              </div>

              <div className="pt-4">
                <button
                  type="submit"
                  disabled={isChangingPassword}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isChangingPassword ? 'Saving...' : t('pages.accountPage.changePassword')}
                </button>
              </div>
            </div>
          </form>

          {/* Delete Account Section */}
          <div className="p-6">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
              {deleteAccountTranslations.title}
            </h2>
            <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
              {deleteAccountTranslations.message}
            </p>
            <button
              type="button"
              onClick={() => setIsDeleteModalOpen(true)}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <Trash2 className="h-4 w-4 mr-2" />
              {deleteAccountTranslations.buttons.delete}
            </button>
          </div>
        </div>
      </div>

      <DeleteAccountModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      />
    </div>
  );
};

export default AccountPage;
