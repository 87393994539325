export const labelsPage = {
  title: 'Etiketler',
  subtitle: 'İşlemlerinizi etiketlerle organize edin',
  noLabels: 'Hiç etiket bulunamadı',
  new: 'Yeni Etiket',
  filters: {
    color: 'Renk',
    type: 'Etiket Türü'
  },
  labelTypes: {
    transaction: 'İşlem',
    budget: 'Bütçe',
    wallet: 'Cüzdan'
  },
  actions: {
    edit: 'Düzenle',
    delete: 'Sil',
    assign: 'Etiket Ata'
  },
  details: {
    transactions: 'İşlemler',
    noTransactions: 'Hiç işlem bulunamadı',
    usageCount: 'işlem'
  },
  validation: {
    nameRequired: 'Etiket adı zorunludur',
    colorRequired: 'Etiket rengi zorunludur'
  },
  colorPicker: {
    title: 'Etiket Rengini Seç',
    preset: 'Hazır Renkler',
    custom: 'Özel Renk'
  }
};
