import { Transaction, Wallet, Budget } from '../types';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

export type ExportFormat = 'excel' | 'json' | 'pdf';

export const exportTransactions = (transactions: Transaction[], format: ExportFormat) => {
  const data = transactions.map(t => ({
    Date: new Date(t.date).toLocaleDateString(),
    Description: t.description,
    Amount: t.amount,
    Type: t.type,
    Category: t.category,
    Status: t.status,
    Labels: t.labels?.join(', ') || '',
    Note: t.note || ''
  }));

  switch (format) {
    case 'excel':
      exportToExcel(data, 'transactions');
      break;
    case 'json':
      exportToJson(transactions, 'transactions');
      break;
    case 'pdf':
      exportToPdf(data, 'transactions');
      break;
  }
};

export const exportWallets = (wallets: Wallet[], format: ExportFormat) => {
  const data = wallets.map(w => ({
    Name: w.name,
    Balance: w.balance,
    Currency: w.currency
  }));

  switch (format) {
    case 'excel':
      exportToExcel(data, 'wallets');
      break;
    case 'json':
      exportToJson(wallets, 'wallets');
      break;
    case 'pdf':
      exportToPdf(data, 'wallets');
      break;
  }
};

export const exportBudgets = (budgets: Budget[], format: ExportFormat) => {
  const data = budgets.map(b => ({
    Name: b.name,
    Amount: b.amount,
    Spent: b.spent,
    Currency: b.currency,
    StartDate: new Date(b.startDate).toLocaleDateString(),
    EndDate: b.endDate ? new Date(b.endDate).toLocaleDateString() : '-'
  }));

  switch (format) {
    case 'excel':
      exportToExcel(data, 'budgets');
      break;
    case 'json':
      exportToJson(budgets, 'budgets');
      break;
    case 'pdf':
      exportToPdf(data, 'budgets');
      break;
  }
};

const exportToExcel = (data: any[], filename: string) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, `${filename}.xlsx`);
};

const exportToJson = (data: any[], filename: string) => {
  const jsonString = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonString], { type: 'application/json' });
  saveAs(blob, `${filename}.json`);
};

const exportToPdf = (data: any[], filename: string) => {
  const doc = new jsPDF();
  
  autoTable(doc, {
    head: [Object.keys(data[0])],
    body: data.map(item => Object.values(item)),
    styles: { fontSize: 8 },
    headStyles: { fillColor: [75, 85, 99] }
  });
  
  doc.save(`${filename}.pdf`);
};
