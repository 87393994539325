import { useState } from 'react';
import { Plus, CreditCard as CreditCardIcon, Pencil, Trash2, X } from 'lucide-react';
import { useCreditCards } from '../context/CreditCardContext';
import { useLanguage } from '../context/LanguageContext';
import CreditCardModal from '../components/creditCards/CreditCardModal';
import PaymentNetworkLogo from '../components/creditCards/PaymentNetworkLogo';
import { CreditCard } from '../types/creditCard';

const getCardBackground = (color: string) => {
  const backgrounds = {
    indigo: 'bg-indigo-600',
    emerald: 'bg-emerald-600',
    amber: 'bg-amber-600'
  };
  return backgrounds[color as keyof typeof backgrounds] || backgrounds.indigo;
};

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, cardName }: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  cardName: string;
}) => {
  if (!isOpen) return null;

  return (
    <>
      <div 
        className="fixed inset-0 bg-black/30 backdrop-blur-sm z-50"
        onClick={onClose}
      />
      <div className="fixed inset-0 flex items-center justify-center z-50 pointer-events-none">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl p-6 max-w-md w-full mx-4 pointer-events-auto">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Delete Credit Card
            </h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
          <p className="text-gray-600 dark:text-gray-400 mb-6">
            Are you sure you want to delete the credit card "{cardName}"? This action cannot be undone.
          </p>
          <div className="flex justify-end gap-4">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                onConfirm();
                onClose();
              }}
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const CreditCardsPage = () => {
  const { creditCards, isLoading, error, addCreditCard, updateCreditCard, deleteCreditCard } = useCreditCards();
  const { t } = useLanguage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingCard, setEditingCard] = useState<CreditCard | undefined>(undefined);
  const [deleteConfirmation, setDeleteConfirmation] = useState<{ isOpen: boolean; cardId: string; cardName: string }>({
    isOpen: false,
    cardId: '',
    cardName: ''
  });

  const handleDelete = (id: string) => {
    deleteCreditCard(id);
  };

  const handleSubmit = (data: Omit<CreditCard, 'id'>) => {
    if (editingCard) {
      updateCreditCard(editingCard.id, data);
    } else {
      addCreditCard(data);
    }
    setIsModalOpen(false);
    setEditingCard(undefined);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-8 text-center">
        <p className="text-red-600 dark:text-red-400">{error}</p>
      </div>
    );
  }

  return (
    <div className="p-8">
      <div className="mb-6">
        <div className="flex items-center justify-between mb-4">
          <div>
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
              {t('pages.creditCardsPage.title')}
            </h1>
            <p className="text-gray-600 dark:text-gray-400">
              {t('pages.creditCardsPage.subtitle')}
            </p>
          </div>
          <button
            onClick={() => {
              setEditingCard(undefined);
              setIsModalOpen(true);
            }}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            {t('pages.creditCardsPage.new')}
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {creditCards.map(card => (
          <div key={card.id} className="group">
            <div className={`relative rounded-xl ${getCardBackground(card.color || 'indigo')} transition-transform duration-300 group-hover:scale-[1.02] shadow-xl`}>
              <div className="absolute top-4 right-4 flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-20">
                <button
                  onClick={() => {
                    setEditingCard(card);
                    setIsModalOpen(true);
                  }}
                  className="p-1.5 text-white hover:text-gray-200 rounded-full hover:bg-white/10"
                >
                  <Pencil className="h-4 w-4" />
                </button>
                <button
                  onClick={() => setDeleteConfirmation({ isOpen: true, cardId: card.id, cardName: card.name })}
                  className="p-1.5 text-white hover:text-red-300 rounded-full hover:bg-white/10"
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              </div>

              <div className="absolute top-4 right-20 z-10">
                <div className="text-white/90">
                  <PaymentNetworkLogo type={card.type || 'other'} />
                </div>
              </div>

              <div className="p-6 text-white">
                <h3 className="text-lg font-medium mb-8">{card.name}</h3>

                <div className="space-y-6">
                  <div>
                    <div className="text-sm text-white/70">{t('pages.creditCardsPage.fields.cardNumber')}</div>
                    <div className="font-medium">•••• •••• •••• {card.lastFourDigits}</div>
                  </div>

                  <div className="flex justify-between">
                    <div>
                      <div className="text-sm text-white/70">{t('pages.creditCardsPage.fields.validThru')}</div>
                      <div className="font-medium">{card.expiryDate}</div>
                    </div>
                    <div>
                      <div className="text-sm text-white/70">{t('pages.creditCardsPage.fields.balance')}</div>
                      <div className="font-medium">
                        {card.balance.toLocaleString('en-US', {
                          style: 'currency',
                          currency: card.currency
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="border-t border-white/20 pt-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <div className="text-sm text-white/70">
                          {t('pages.creditCardsPage.fields.limit')}
                        </div>
                        <div className="font-medium">
                          {card.limit.toLocaleString('en-US', {
                            style: 'currency',
                            currency: card.currency
                          })}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm text-white/70">
                          {t('pages.creditCardsPage.fields.available')}
                        </div>
                        <div className="font-medium text-emerald-300">
                          {(card.limit - card.balance).toLocaleString('en-US', {
                            style: 'currency',
                            currency: card.currency
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <CreditCardModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditingCard(undefined);
        }}
        onSubmit={handleSubmit}
        initialData={editingCard}
      />

      <DeleteConfirmationModal
        isOpen={deleteConfirmation.isOpen}
        onClose={() => setDeleteConfirmation({ isOpen: false, cardId: '', cardName: '' })}
        onConfirm={() => handleDelete(deleteConfirmation.cardId)}
        cardName={deleteConfirmation.cardName}
      />
    </div>
  );
};

export default CreditCardsPage;
