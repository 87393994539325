export const accountPage = {
  title: 'Account Settings',
  personalInfo: 'Personal Information',
  name: 'Full Name',
  email: 'Email',
  language: 'Language',
  currency: 'Currency',
  theme: 'Theme',
  notifications: 'Notifications',
  security: 'Security',
  changePassword: 'Change Password',
  currentPassword: 'Current Password',
  newPassword: 'New Password',
  confirmNewPassword: 'Confirm New Password',
  updateButton: 'Update Profile',
  photo: {
    upload: 'Upload Photo',
    remove: 'Remove Photo'
  },
  errors: {
    nameRequired: 'Full name is required',
    emailRequired: 'Email is required',
    emailInvalid: 'Please enter a valid email address',
    currentPasswordRequired: 'Current password is required',
    newPasswordRequired: 'New password is required',
    newPasswordTooShort: 'New password must be at least 8 characters',
    passwordMismatch: 'New passwords do not match'
  },
  success: {
    profileUpdated: 'Profile updated successfully',
    passwordChanged: 'Password changed successfully'
  },
  languageOptions: {
    english: 'English',
    turkish: 'Turkish'
  },
  themeOptions: {
    light: 'Light',
    dark: 'Dark',
    system: 'System Default'
  },
  currencyOptions: {
    usd: 'USD - US Dollar',
    eur: 'EUR - Euro',
    gbp: 'GBP - British Pound',
    try: 'TRY - Turkish Lira',
    jpy: 'JPY - Japanese Yen',
    cny: 'CNY - Chinese Yuan'
  }
};
