import React, { useState, useEffect } from 'react';
import { Plus, Edit2, Trash2, Package, Grid } from 'lucide-react';
import { SubscriptionService } from '../../types/subscription';
import AdminPlatformModal from '../../components/admin/AdminPlatformModal';
import PlatformCategoryModal from '../../components/admin/PlatformCategoryModal';
import {
  getAllPlatforms,
  createPlatform,
  updatePlatform,
  deletePlatform,
  initializePlatforms
} from '../../services/subscriptionPlatforms';
import {
  PlatformCategory,
  getAllCategories,
  createCategory,
  updateCategory,
  deleteCategory,
  initializeCategories
} from '../../services/platformCategories';

type PlatformWithId = SubscriptionService & { id: string };

const AdminPlatforms = () => {
  const [platforms, setPlatforms] = useState<PlatformWithId[]>([]);
  const [categories, setCategories] = useState<PlatformCategory[]>([]);
  const [editingPlatform, setEditingPlatform] = useState<PlatformWithId | undefined>();
  const [editingCategory, setEditingCategory] = useState<PlatformCategory | undefined>();
  const [isPlatformModalOpen, setIsPlatformModalOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      await Promise.all([initializePlatforms(), initializeCategories()]);
      const [platformsData, categoriesData] = await Promise.all([
        getAllPlatforms(),
        getAllCategories()
      ]);
      setPlatforms(platformsData);
      setCategories(categoriesData);
    } catch (error) {
      console.error('Error loading data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddPlatform = async (data: SubscriptionService) => {
    try {
      const id = await createPlatform(data);
      setPlatforms(current => [...current, { ...data, id }]);
    } catch (error) {
      console.error('Error adding platform:', error);
    }
  };

  const handleUpdatePlatform = async (data: PlatformWithId) => {
    try {
      await updatePlatform(data.id, data);
      setPlatforms(current =>
        current.map(platform =>
          platform.id === data.id ? data : platform
        )
      );
    } catch (error) {
      console.error('Error updating platform:', error);
    }
  };

  const handleDeletePlatform = async (platformId: string) => {
    if (window.confirm('Are you sure you want to delete this platform?')) {
      try {
        await deletePlatform(platformId);
        setPlatforms(current =>
          current.filter(platform => platform.id !== platformId)
        );
      } catch (error) {
        console.error('Error deleting platform:', error);
      }
    }
  };

  const handleAddCategory = async (data: PlatformCategory) => {
    try {
      const id = await createCategory(data);
      setCategories(current => [...current, { ...data, id }]);
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  const handleUpdateCategory = async (data: PlatformCategory) => {
    if (!data.id) return;
    try {
      await updateCategory(data.id, data);
      setCategories(current =>
        current.map(category =>
          category.id === data.id ? data : category
        )
      );
    } catch (error) {
      console.error('Error updating category:', error);
    }
  };

  const handleDeleteCategory = async (categoryId: string) => {
    if (window.confirm('Are you sure you want to delete this category? This will affect all platforms using this category.')) {
      try {
        await deleteCategory(categoryId);
        setCategories(current =>
          current.filter(category => category.id !== categoryId)
        );
      } catch (error) {
        console.error('Error deleting category:', error);
        alert(error instanceof Error ? error.message : 'Failed to delete category');
      }
    }
  };

  const handlePlatformModalSubmit = async (data: SubscriptionService & { id?: string }) => {
    if (data.id) {
      await handleUpdatePlatform(data as PlatformWithId);
    } else {
      await handleAddPlatform(data);
    }
    setIsPlatformModalOpen(false);
  };

  const handleCategoryModalSubmit = async (data: PlatformCategory) => {
    if (data.id) {
      await handleUpdateCategory(data);
    } else {
      await handleAddCategory(data);
    }
    setIsCategoryModalOpen(false);
  };

  if (isLoading) {
    return (
      <div className="p-8 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600" />
      </div>
    );
  }

  return (
    <div className="p-8">
      {/* Categories Section */}
      <div className="mb-12">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h2 className="text-xl font-bold text-gray-900 dark:text-white">Categories</h2>
            <p className="text-gray-600 dark:text-gray-400">Manage platform categories</p>
          </div>
          <button
            onClick={() => {
              setEditingCategory(undefined);
              setIsCategoryModalOpen(true);
            }}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            Add Category
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {categories.map(category => (
            <div
              key={category.id}
              className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 p-6"
            >
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-3">
                  {category.icon ? (
                    <img
                      src={category.icon}
                      alt={category.name}
                      className="w-8 h-8 rounded-lg object-cover"
                    />
                  ) : (
                    <div className="p-2 bg-indigo-100 dark:bg-indigo-900/50 rounded-lg">
                      <Grid className="h-4 w-4 text-indigo-600 dark:text-indigo-400" />
                    </div>
                  )}
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                      {category.name}
                    </h3>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {category.slug}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => {
                      setEditingCategory(category);
                      setIsCategoryModalOpen(true);
                    }}
                    className="p-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <Edit2 className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => category.id && handleDeleteCategory(category.id)}
                    className="p-2 text-red-400 hover:text-red-600 rounded-full hover:bg-red-100"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                </div>
              </div>
              {category.description && (
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  {category.description}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Platforms Section */}
      <div>
        <div className="flex items-center justify-between mb-6">
          <div>
            <h2 className="text-xl font-bold text-gray-900 dark:text-white">Platforms</h2>
            <p className="text-gray-600 dark:text-gray-400">Manage subscription platforms</p>
          </div>
          <button
            onClick={() => {
              setEditingPlatform(undefined);
              setIsPlatformModalOpen(true);
            }}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            Add Platform
          </button>
        </div>

        <div className="space-y-8">
          {categories.map(category => {
            const categoryPlatforms = platforms.filter(p => p.category === category.slug);
            if (categoryPlatforms.length === 0) return null;

            return (
              <div key={category.id}>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white capitalize mb-4">
                  {category.name}
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {categoryPlatforms.map(platform => (
                    <div
                      key={platform.id}
                      className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 p-6"
                    >
                      <div className="flex items-center justify-between mb-4">
                        <div className="flex items-center gap-3">
                          {platform.logo ? (
                            <img
                              src={platform.logo}
                              alt={platform.name}
                              className="w-10 h-10 rounded-lg object-cover"
                            />
                          ) : (
                            <div className="p-2 bg-indigo-100 dark:bg-indigo-900/50 rounded-lg">
                              <Package className="h-6 w-6 text-indigo-600 dark:text-indigo-400" />
                            </div>
                          )}
                          <div>
                            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                              {platform.name}
                            </h3>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <button
                            onClick={() => {
                              setEditingPlatform(platform);
                              setIsPlatformModalOpen(true);
                            }}
                            className="p-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                          >
                            <Edit2 className="h-4 w-4" />
                          </button>
                          <button
                            onClick={() => handleDeletePlatform(platform.id)}
                            className="p-2 text-red-400 hover:text-red-600 rounded-full hover:bg-red-100"
                          >
                            <Trash2 className="h-4 w-4" />
                          </button>
                        </div>
                      </div>

                      {platform.description && (
                        <p className="text-sm text-gray-600 dark:text-gray-300 mb-4">
                          {platform.description}
                        </p>
                      )}

                      {platform.color && (
                        <div className="flex items-center gap-2">
                          <div
                            className="w-4 h-4 rounded-full"
                            style={{ backgroundColor: platform.color }}
                          />
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            Brand Color
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <AdminPlatformModal
        isOpen={isPlatformModalOpen}
        onClose={() => {
          setIsPlatformModalOpen(false);
          setEditingPlatform(undefined);
        }}
        onSubmit={handlePlatformModalSubmit}
        initialData={editingPlatform}
      />

      <PlatformCategoryModal
        isOpen={isCategoryModalOpen}
        onClose={() => {
          setIsCategoryModalOpen(false);
          setEditingCategory(undefined);
        }}
        onSubmit={handleCategoryModalSubmit}
        initialData={editingCategory}
      />
    </div>
  );
};

export default AdminPlatforms;
