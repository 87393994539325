import React, { useState} from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { ChevronRight, ChevronLeft } from 'lucide-react';
import { useBudgets } from '../context/BudgetContext';
import { useWallets } from '../context/WalletContext';
import { useCategories } from '../context/CategoryContext';
import { useLanguage } from '../context/LanguageContext';
import { useCurrency } from '../context/CurrencyContext';
import { getTranslations } from '../locales/pages/budgetDetailsPage';
import BudgetModal from '../components/budgets/BudgetModal';
import DeleteConfirmationModal from '../components/budgets/DeleteConfirmationModal';

const BudgetDetailsPage: React.FC = () => {
  const { budgetId } = useParams<{ budgetId: string }>();
  const { budgets, deleteBudget } = useBudgets();
  const { wallets } = useWallets();
  const { categories } = useCategories();
  const { language: currentLanguage } = useLanguage();
  const { formatAmount } = useCurrency();
  const translations = getTranslations(currentLanguage);
  const navigate = useNavigate();
  const rawBudget = budgets.find(b => b.id === budgetId);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  if (!rawBudget) {
    return (
      <div className="p-6">
        <div className="text-gray-500">{translations.noTransactions}</div>
      </div>
    );
  }

  // Calculate the remaining amount
  const budget = {
    ...rawBudget,
    remaining: rawBudget.amount - rawBudget.spent
  };

  const handleDelete = async () => {
    try {
      await deleteBudget(budget.id);
      navigate('/budgets');
    } catch (error) {
      console.error('Failed to delete budget:', error);
    }
  };

  const startDate = new Date(budget.startDate);
  const endDate = budget.endDate ? new Date(budget.endDate) : undefined;
  const today = new Date();
  const daysLeft = endDate ? Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)) : 0;
  const dailyAmount = budget.remaining / (daysLeft || 1);
  const isOverBudget = budget.remaining < 0;
  const spentPercentage = (budget.spent / budget.amount) * 100;

  const getWalletNames = (walletIds: string[]) => {
    if (walletIds.length === 0) return translations.allWallets;
    return walletIds
      .map(id => wallets.find(w => w.id === id)?.name || '')
      .filter(name => name !== '')
      .join(', ');
  };

  const getCategoryNames = (categoryIds: string[]) => {
    if (categoryIds.length === 0) return translations.allCategories;
    return categoryIds
      .map(id => categories.find(c => c.id === id)?.name || '')
      .filter(name => name !== '')
      .join(', ');
  };

  return (
    <div className="p-6 space-y-6">
      {/* Navigation */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2 text-gray-600">
          <Link to="/budgets" className="hover:text-gray-900">{translations.title}</Link>
          <ChevronRight className="h-4 w-4" />
          <span className="font-medium text-gray-900">{budget.name}</span>
        </div>
        <div className="flex items-center gap-4">
          <button className="text-gray-500 hover:text-gray-700">
            <ChevronLeft className="h-5 w-5" />
          </button>
          <span className="text-gray-900">
            {startDate.toLocaleDateString(currentLanguage, { 
              month: 'short',
              day: '2-digit',
              year: 'numeric'
            })}
            {' – '}
            {endDate?.toLocaleDateString(currentLanguage, {
              month: 'short',
              day: '2-digit',
              year: 'numeric'
            })}
          </span>
          <button className="text-gray-500 hover:text-gray-700">
            <ChevronRight className="h-5 w-5" />
          </button>
        </div>
        <div className="flex items-center gap-2">
          <button 
            onClick={() => setIsEditModalOpen(true)}
            className="px-4 py-2 text-sm font-medium text-green-500 bg-green-50 rounded-lg hover:bg-green-100"
          >
            {translations.actions.editBudget}
          </button>
          <button 
            onClick={() => setIsDeleteModalOpen(true)}
            className="px-4 py-2 text-sm font-medium text-red-500 bg-red-50 rounded-lg hover:bg-red-100"
          >
            {translations.actions.deleteBudget}
          </button>
        </div>
      </div>

      {/* Budget Information */}
      <div className="bg-white rounded-lg p-4 shadow">
        <div className="space-y-2">
          <h2 className="text-lg font-medium text-gray-900">{budget.name}</h2>
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-2">
              <span className="text-xs font-medium text-gray-500 uppercase">
                {translations.wallets}:
              </span>
              <span className="text-sm text-gray-600">
                {getWalletNames(budget.wallets)}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-xs font-medium text-gray-500 uppercase">
                {translations.categories}:
              </span>
              <span className="text-sm text-gray-600">
                {getCategoryNames(budget.categoryIds)}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Budget Statistics */}
      <div className="grid grid-cols-4 gap-6">
        <div className="bg-white rounded-lg p-4 shadow">
          <div className="text-sm text-gray-500 mb-1">{translations.totalBudget}</div>
          <div className="text-xl font-medium text-green-500">
            {formatAmount(budget.amount, budget.currency)}
          </div>
        </div>
        <div className="bg-white rounded-lg p-4 shadow">
          <div className="text-sm text-gray-500 mb-1">{translations.spent}</div>
          <div className="text-xl font-medium">
            {formatAmount(Math.abs(budget.spent), budget.currency)}
          </div>
        </div>
        <div className="bg-white rounded-lg p-4 shadow">
          <div className="text-sm text-gray-500 mb-1">{translations.remaining}</div>
          <div className={`text-xl font-medium ${isOverBudget ? 'text-red-500' : 'text-green-500'}`}>
            {formatAmount(budget.remaining, budget.currency)}
          </div>
        </div>
        <div className="bg-white rounded-lg p-4 shadow">
          <div className="text-sm text-gray-500 mb-1">{translations.insights.title}</div>
          <div className={`text-xl font-medium ${isOverBudget ? 'text-red-500' : 'text-purple-600'}`}>
            {formatAmount(dailyAmount, budget.currency)}/Day
          </div>
        </div>
      </div>

      {/* Budget Progress */}
      <div className="space-y-6">
        <h2 className="text-lg font-medium text-gray-900">{translations.overview}</h2>

        <div className="relative">
          {/* Progress Bar */}
          <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
            <div 
              className={`h-full ${isOverBudget ? 'bg-red-500' : 'bg-green-500'} rounded-full transition-all duration-300`}
              style={{ width: `${isOverBudget ? 100 : Math.min(spentPercentage, 100)}%` }}
            />
          </div>

          {/* Date Labels */}
          <div className="flex justify-between mt-2 text-sm text-gray-500">
            <div>{startDate.toLocaleDateString(currentLanguage)}</div>
            <div>{endDate?.toLocaleDateString(currentLanguage)}</div>
          </div>
        </div>

        {/* Categories */}
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-gray-900">{translations.categories}</h3>
          </div>

          {/* Category Progress */}
          <div className="bg-white rounded-lg shadow p-4">
            <div className="flex justify-between items-center text-sm text-gray-500 mb-2">
              <div>
                {translations.spent}: {formatAmount(Math.abs(budget.spent), budget.currency)}
                ({spentPercentage.toFixed(1)}%)
              </div>
              <div>
                {startDate.toLocaleDateString(currentLanguage)} - {endDate?.toLocaleDateString(currentLanguage)}
              </div>
            </div>
            <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
              <div 
                className={`h-full ${isOverBudget ? 'bg-red-500' : 'bg-green-500'} rounded-full`}
                style={{ width: `${isOverBudget ? 100 : Math.min(spentPercentage, 100)}%` }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Edit Budget Modal */}
      <BudgetModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        budget={budget}
      />

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDelete}
        budgetName={budget.name}
      />
    </div>
  );
};

export default BudgetDetailsPage;
