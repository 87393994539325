import React, { useState } from 'react';
import { Plus, PieChart, Users } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useBudgets } from '../context/BudgetContext';
import { useWallets } from '../context/WalletContext';
import { useCategories } from '../context/CategoryContext';
import { useLanguage } from '../context/LanguageContext';
import { useAuth } from '../context/AuthContext';
import { useCurrency } from '../context/CurrencyContext';
import BudgetModal from '../components/budgets/BudgetModal';
import ProgressCircle from '../components/budgets/ProgressCircle';
import ExportButton from '../components/ExportButton';
import { exportBudgets } from '../utils/export';
import { checkPlanLimit } from '../utils/planLimits';
import PlanLimitModal from '../components/PlanLimitModal';
import { budgetsPage as enTranslations } from '../locales/pages/budgetsPage/en';
import { budgetsPage as trTranslations } from '../locales/pages/budgetsPage/tr';

const BudgetsPage = () => {
  const navigate = useNavigate();
  const { budgets } = useBudgets();
  const { wallets } = useWallets();
  const { categories } = useCategories();
  const { language } = useLanguage();
  const { user } = useAuth();
  const { formatAmount } = useCurrency();
  const t = language === 'tr' ? trTranslations : enTranslations;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPlanLimitModal, setShowPlanLimitModal] = useState(false);

  const getWalletNames = (walletIds: string[]) => {
    if (walletIds.length === 0) return t.allWallets;
    return walletIds
      .map(id => wallets.find(w => w.id === id)?.name || '')
      .filter(name => name !== '')
      .join(', ');
  };

  const getCategoryNames = (categoryIds: string[]) => {
    if (categoryIds.length === 0) return t.allCategories;
    return categoryIds
      .map(id => categories.find(c => c.id === id)?.name || '')
      .filter(name => name !== '')
      .join(', ');
  };

  const handleAddBudgetClick = () => {
    const canAddBudget = checkPlanLimit(
      (user?.subscription?.plan === 'business' ? 'premium' : user?.subscription?.plan || 'free') as 'free' | 'premium',
      'budgets',
      budgets.length
    );

    if (!canAddBudget) {
      setShowPlanLimitModal(true);
      return;
    }

    setIsModalOpen(true);
  };

  const handleExport = (format: 'excel' | 'json' | 'pdf') => {
    exportBudgets(budgets, format);
  };

  return (
    <div className="p-8">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            {t.title}
          </h1>
          <p className="text-gray-600 dark:text-gray-400">
            {t.subtitle}
          </p>
        </div>
        <div className="flex items-center gap-4">
          <ExportButton onExport={handleExport} />
          <button
            onClick={handleAddBudgetClick}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            {t.addBudgetButton}
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {budgets.map(budget => {
          const spentPercentage = (budget.spent / budget.amount) * 100;
          const isOverBudget = budget.spent > budget.amount;

          return (
            <div
              key={budget.id}
              className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 p-6 hover:shadow-md transition-shadow cursor-pointer"
              onClick={() => navigate(`/budgets/${budget.id}`)}
            >
              <div className="flex items-center justify-between mb-4">
                <div className="p-3 rounded-lg bg-indigo-100 dark:bg-indigo-900">
                  <PieChart className="h-6 w-6 text-indigo-600 dark:text-indigo-400" />
                </div>
                <div className="flex items-center gap-2">
                  {budget.wallets && budget.wallets.length > 0 && (
                    <div className="flex items-center gap-1 px-2 py-1 rounded-full bg-gray-100 dark:bg-gray-700">
                      <Users className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                      <span className="text-xs text-gray-600 dark:text-gray-300">
                        {budget.wallets.length}
                      </span>
                    </div>
                  )}
                  <span className="px-3 py-1 rounded-full text-sm font-medium bg-indigo-100 dark:bg-indigo-900 text-indigo-700 dark:text-indigo-300">
                    {budget.currency}
                  </span>
                </div>
              </div>

              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">{budget.name}</h3>
              <div className="flex items-center justify-between mb-4">
                <p className="text-2xl font-bold text-gray-900 dark:text-white">
                  {formatAmount(budget.spent, budget.currency)}
                  <span className="text-sm font-normal text-gray-500 dark:text-gray-400 ml-1">
                    / {formatAmount(budget.amount, budget.currency)}
                  </span>
                </p>
                <ProgressCircle
                  percentage={spentPercentage}
                  isOverBudget={isOverBudget}
                />
              </div>

              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <span className="text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    {t.wallets}:
                  </span>
                  <span className="text-sm text-gray-600 dark:text-gray-300">
                    {getWalletNames(budget.wallets)}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    {t.categories}:
                  </span>
                  <span className="text-sm text-gray-600 dark:text-gray-300">
                    {getCategoryNames(budget.categoryIds)}
                  </span>
                </div>
              </div>

              <div className="mt-6 pt-4 border-t border-gray-100 dark:border-gray-700">
                <div className="flex items-center justify-between text-sm">
                  <span className="text-gray-500 dark:text-gray-400">
                    {new Date(budget.startDate).toLocaleDateString(language === 'tr' ? 'tr-TR' : 'en-US', { 
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit'
                    })}
                    {budget.endDate && (
                      <>
                        {' - '}
                        {new Date(budget.endDate).toLocaleDateString(language === 'tr' ? 'tr-TR' : 'en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit'
                        })}
                      </>
                    )}
                  </span>
                  <span className="text-indigo-600 dark:text-indigo-400 font-medium">
                    {t.budgetCard.viewDetails} →
                  </span>
                </div>
              </div>
            </div>
          );
        })}

        {checkPlanLimit(
          (user?.subscription?.plan === 'business' ? 'premium' : user?.subscription?.plan || 'free') as 'free' | 'premium',
          'budgets',
          budgets.length
        ) && (
          <button
            onClick={handleAddBudgetClick}
            className="flex flex-col items-center justify-center p-6 border-2 border-dashed border-gray-200 dark:border-gray-700 rounded-xl hover:border-indigo-500 dark:hover:border-indigo-400 hover:bg-indigo-50 dark:hover:bg-indigo-900/50 transition-colors"
          >
            <div className="w-12 h-12 rounded-full bg-indigo-100 dark:bg-indigo-900 flex items-center justify-center mb-3">
              <Plus className="h-6 w-6 text-indigo-600 dark:text-indigo-400" />
            </div>
            <span className="text-sm font-medium text-gray-900 dark:text-white">
              {t.addNew.title}
            </span>
            <span className="text-xs text-gray-500 dark:text-gray-400">
              {t.addNew.description}
            </span>
          </button>
        )}
      </div>

      <BudgetModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />

      <PlanLimitModal
        isOpen={showPlanLimitModal}
        onClose={() => setShowPlanLimitModal(false)}
        feature="budgets"
      />
    </div>
  );
};

export default BudgetsPage;
