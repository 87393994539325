const translations = {
  navigation: {
    backToWallets: 'Back to Wallets'
  },
  header: {
    subtitle: 'Wallet Details and Transactions',
    buttons: {
      edit: 'Edit Wallet',
      delete: 'Delete Wallet'
    }
  },
  stats: {
    currentBalance: 'Current Balance',
    totalIncome: 'Total Income',
    totalExpenses: 'Total Expenses',
    savingsRate: 'Savings Rate'
  },
  transactions: {
    title: 'Transactions History',
    dateRanges: {
      today: 'Today',
      thisWeek: 'This Week',
      thisMonth: 'This Month'
    },
    datePicker: {
      to: 'to'
    },
    empty: 'No transactions found for this wallet in the selected date range'
  },
  confirmations: {
    deleteWallet: 'Are you sure you want to delete this wallet? This action cannot be undone.'
  },
  errors: {
    notFound: {
      title: 'Wallet not found',
      action: 'Return to Wallets'
    }
  }
};

export default translations;
