const translations = {
  navigation: {
    backToWallets: 'Cüzdanlara Geri Dön'
  },
  header: {
    subtitle: 'Cüzdan Detayları ve İşlemler',
    buttons: {
      edit: 'Cüzdanı Düzenle',
      delete: 'Cüzdanı Sil'
    }
  },
  stats: {
    currentBalance: 'Mevcut Bakiye',
    totalIncome: 'Toplam Gelir',
    totalExpenses: 'Toplam Gider',
    savingsRate: 'Tasarruf Oranı'
  },
  transactions: {
    title: 'İşlem Geçmişi',
    dateRanges: {
      today: 'Bugün',
      thisWeek: 'Bu Hafta',
      thisMonth: 'Bu Ay'
    },
    datePicker: {
      to: 'ile'
    },
    empty: 'Seçilen tarih aralığında bu cüzdan için işlem bulunamadı'
  },
  confirmations: {
    deleteWallet: 'Bu cüzdanı silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.'
  },
  errors: {
    notFound: {
      title: 'Cüzdan bulunamadı',
      action: 'Cüzdanlara Dön'
    }
  }
};

export default translations;
