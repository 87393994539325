import React, { useState } from 'react';
import { Mail, Wallet, Check, AlertCircle } from 'lucide-react';
import { useWallets } from '../context/WalletContext';
import { useLanguage } from '../context/LanguageContext';
import { WalletMember } from '../types';

const InviteMembersPage = () => {
  const { wallets, shareWallet } = useWallets();
  const { t } = useLanguage();
  const [email, setEmail] = useState('');
  const [role, setRole] = useState<WalletMember['role']>('editor');
  const [selectedWallets, setSelectedWallets] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedWallets.length === 0) {
      setError(t('pages.inviteMembersPage.messages.error.selectWallet'));
      return;
    }

    setIsLoading(true);
    setError(null);
    setSuccess(null);

    try {
      await Promise.all(selectedWallets.map(walletId => shareWallet(walletId, email, role)));
      setSuccess(t('pages.inviteMembersPage.messages.success'));
      setEmail('');
      setSelectedWallets([]);
    } catch (err) {
      setError(err instanceof Error ? err.message : t('pages.inviteMembersPage.messages.error.sendFailed'));
    } finally {
      setIsLoading(false);
    }
  };

  const toggleWallet = (walletId: string) => {
    setSelectedWallets(current =>
      current.includes(walletId)
        ? current.filter(id => id !== walletId)
        : [...current, walletId]
    );
  };

  return (
    <div className="p-8">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
          {t('pages.inviteMembersPage.title')}
        </h1>
        <p className="text-gray-600 dark:text-gray-400 mb-8">
          {t('pages.inviteMembersPage.subtitle')}
        </p>

        {error && (
          <div className="mb-6 p-4 bg-red-50 dark:bg-red-900/30 rounded-lg flex items-start">
            <AlertCircle className="h-5 w-5 text-red-400 mt-0.5 mr-3" />
            <p className="text-sm text-red-800 dark:text-red-300">{error}</p>
          </div>
        )}

        {success && (
          <div className="mb-6 p-4 bg-green-50 dark:bg-green-900/30 rounded-lg flex items-start">
            <Check className="h-5 w-5 text-green-400 mt-0.5 mr-3" />
            <p className="text-sm text-green-800 dark:text-green-300">{success}</p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 p-6">
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.inviteMembersPage.form.email.label')}
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                  placeholder={t('pages.inviteMembersPage.form.email.placeholder')}
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.inviteMembersPage.form.accessLevel.label')}
              </label>
              <select
                value={role}
                onChange={e => setRole(e.target.value as WalletMember['role'])}
                className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
              >
                <option value="editor">{t('pages.inviteMembersPage.form.accessLevel.options.editor')}</option>
                <option value="viewer">{t('pages.inviteMembersPage.form.accessLevel.options.viewer')}</option>
              </select>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                {t('pages.inviteMembersPage.form.accessLevel.description')}
              </p>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.inviteMembersPage.form.wallets.label')}
              </label>
              <div className="space-y-3">
                {wallets.map(wallet => (
                  <div
                    key={wallet.id}
                    className={`flex items-center p-4 border rounded-lg cursor-pointer transition-colors ${
                      selectedWallets.includes(wallet.id)
                        ? 'border-indigo-500 dark:border-indigo-400 bg-indigo-50 dark:bg-indigo-900/30'
                        : 'border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700'
                    }`}
                    onClick={() => toggleWallet(wallet.id)}
                  >
                    <div className={`p-2 rounded-lg bg-${wallet.color}-100 dark:bg-${wallet.color}-900 mr-4`}>
                      <Wallet className={`h-5 w-5 text-${wallet.color}-600 dark:text-${wallet.color}-400`} />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-sm font-medium text-gray-900 dark:text-white">{wallet.name}</h3>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {wallet.balance.toLocaleString('en-US', {
                          style: 'currency',
                          currency: wallet.currency
                        })}
                      </p>
                    </div>
                    {selectedWallets.includes(wallet.id) ? (
                      <Check className="h-5 w-5 text-indigo-600 dark:text-indigo-400" />
                    ) : (
                      <div className="h-5 w-5 border-2 border-gray-300 dark:border-gray-600 rounded" />
                    )}
                  </div>
                ))}
              </div>
            </div>

            <button
              type="submit"
              disabled={isLoading || selectedWallets.length === 0}
              className="w-full flex justify-center items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            >
              {isLoading 
                ? t('pages.inviteMembersPage.form.submit.sending')
                : t('pages.inviteMembersPage.form.submit.label')
              }
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InviteMembersPage;
