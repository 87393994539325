import React, { useState, useMemo } from 'react';
import { Plus, Edit2, Trash2, Tags, UserCog } from 'lucide-react';
import { Category } from '../../types/category';
import { useCategories } from '../../context/CategoryContext';
import AdminCategoryModal from '../../components/admin/AdminCategoryModal';
import { resetAllUsersCategories, getDefaultCategories } from '../../services/categories';
import { useNotification } from '../../context/NotificationContext';

type AdminCategoryInput = Omit<Category, 'id' | 'transactionCount' | 'wallets' | 'userId' | 'createdAt' | 'updatedAt'>;

const AdminCategories = () => {
  const { 
    defaultCategories, 
    addDefaultCategory, 
    updateDefaultCategory, 
    deleteDefaultCategory,
    isAdmin 
  } = useCategories();
  
  const { showNotification } = useNotification();
  const [searchQuery, setSearchQuery] = useState('');
  const [typeFilter, setTypeFilter] = useState<'all' | 'income' | 'expense'>('all');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingCategory, setEditingCategory] = useState<Category | null>(null);
  const [isResetting, setIsResetting] = useState(false);

  const filteredCategories = useMemo(() => 
    defaultCategories.map(category => ({
      ...category,
      uniqueKey: `category-${category.id}`
    })).filter(category => {
      const matchesSearch = category.name.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesType = typeFilter === 'all' || category.type === typeFilter;
      return matchesSearch && matchesType;
    }),
    [defaultCategories, searchQuery, typeFilter]
  );

  const stats = useMemo(() => ({
    total: defaultCategories.length,
    income: defaultCategories.filter(c => c.type === 'income').length,
    expense: defaultCategories.filter(c => c.type === 'expense').length
  }), [defaultCategories]);

  const handleAddCategory = async (data: AdminCategoryInput) => {
    try {
      await addDefaultCategory(data);
      setIsModalOpen(false);
      setEditingCategory(null);
      showNotification('success', 'Category added successfully');
    } catch (error) {
      console.error('Error adding default category:', error);
      showNotification('error', 'Failed to add category');
    }
  };

  const handleUpdateCategory = async (id: string, data: AdminCategoryInput) => {
    try {
      await updateDefaultCategory(id, data);
      setIsModalOpen(false);
      setEditingCategory(null);
      showNotification('success', 'Category updated successfully');
    } catch (error) {
      console.error('Error updating default category:', error);
      showNotification('error', 'Failed to update category');
    }
  };

  const handleDeleteCategory = async (id: string) => {
    try {
      await deleteDefaultCategory(id);
      showNotification('success', 'Category deleted successfully');
    } catch (error) {
      console.error('Error deleting default category:', error);
      showNotification('error', 'Failed to delete category');
    }
  };

  const handleResetAllUserCategories = async () => {
    if (!window.confirm('Are you sure you want to reset categories for all users? This will replace existing categories with default categories.')) {
      return;
    }

    try {
      setIsResetting(true);
      showNotification('info', 'Starting category reset for all users...');

      // Verify default categories exist
      const defaults = await getDefaultCategories();
      if (!defaults.length) {
        throw new Error('No default categories found');
      }

      await resetAllUsersCategories();
      showNotification('success', 'Successfully reset categories for all users');
    } catch (error) {
      console.error('Error resetting user categories:', error);
      showNotification('error', error instanceof Error ? error.message : 'Failed to reset user categories');
    } finally {
      setIsResetting(false);
    }
  };

  if (!isAdmin) {
    return (
      <div className="p-8 text-center">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Access Denied</h2>
        <p className="text-gray-600 dark:text-gray-400 mt-2">You don't have permission to access this page.</p>
      </div>
    );
  }

  return (
    <div className="p-8">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Default Categories</h1>
          <p className="text-gray-600 dark:text-gray-400">Manage default transaction categories for all users</p>
        </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={handleResetAllUserCategories}
            disabled={isResetting}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-orange-600 rounded-lg hover:bg-orange-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <UserCog className="h-4 w-4 mr-2" />
            {isResetting ? 'Resetting Categories...' : 'Reset User Categories'}
          </button>
          <button
            onClick={() => {
              setEditingCategory(null);
              setIsModalOpen(true);
            }}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            Add Category
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 p-6">
          <div className="flex items-center gap-3 mb-2">
            <div className="p-2 bg-indigo-100 dark:bg-indigo-900/50 rounded-lg">
              <Tags className="h-5 w-5 text-indigo-600 dark:text-indigo-400" />
            </div>
            <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Total Categories</h3>
          </div>
          <p className="text-2xl font-bold text-gray-900 dark:text-white">{stats.total}</p>
          <div className="mt-2 flex items-center justify-between text-sm">
            <span className="text-gray-500 dark:text-gray-400">Income: {stats.income}</span>
            <span className="text-gray-500 dark:text-gray-400">Expense: {stats.expense}</span>
          </div>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700">
        <div className="p-4 border-b border-gray-100 dark:border-gray-700">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1">
              <input
                type="text"
                placeholder="Search categories..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
              />
            </div>
            <select
              value={typeFilter}
              onChange={(e) => setTypeFilter(e.target.value as typeof typeFilter)}
              className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
            >
              <option value="all">All Types</option>
              <option value="income">Income</option>
              <option value="expense">Expense</option>
            </select>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50 dark:bg-gray-700">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Category</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Type</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Icon</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Color</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 dark:divide-gray-700">
              {filteredCategories.map(category => (
                <tr key={category.uniqueKey} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="text-sm font-medium text-gray-900 dark:text-white">
                      {category.name}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      category.type === 'income'
                        ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                        : 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300'
                    }`}>
                      {category.type.charAt(0).toUpperCase() + category.type.slice(1)}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className={`p-2 rounded-lg bg-${category.color}-100 dark:bg-${category.color}-900/50 inline-block`}>
                      <Tags className={`h-4 w-4 text-${category.color}-600 dark:text-${category.color}-400`} />
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      {category.color.charAt(0).toUpperCase() + category.color.slice(1)}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => {
                          setEditingCategory(category);
                          setIsModalOpen(true);
                        }}
                        className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
                        title="Edit Category"
                      >
                        <Edit2 className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                      </button>
                      <button
                        onClick={() => handleDeleteCategory(category.id)}
                        className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
                        title="Delete Category"
                      >
                        <Trash2 className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <AdminCategoryModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditingCategory(null);
        }}
        onSubmit={editingCategory ? 
          (data) => handleUpdateCategory(editingCategory.id, data) : 
          handleAddCategory
        }
        initialData={editingCategory}
      />
    </div>
  );
};

export default AdminCategories;
