export const reportsPage = {
  title: 'Finansal Raporlar',
  subtitle: 'Finansal aktivitelerinizi ve trendleri analiz edin',
  noReportsAvailable: 'Rapor bulunamadı',
  generateReportButton: 'Rapor Oluştur',
  filters: {
    dateRange: 'Tarih Aralığı',
    category: 'Kategori',
    wallet: 'Cüzdan',
    transactionType: 'İşlem Türü'
  },
  reportTypes: {
    expenses: 'Gider Raporu',
    income: 'Gelir Raporu',
    cashFlow: 'Nakit Akışı Raporu',
    categoryBreakdown: 'Kategori Dağılımı Raporu'
  },
  summaryCards: {
    totalBalance: 'Toplam Bakiye',
    totalIncome: 'Toplam Gelir',
    totalExpenses: 'Toplam Gider',
    savingsRate: 'Tasarruf Oranı',
    creditUtilization: 'Kredi Kullanımı',
    monthlySubscriptions: 'Aylık Abonelikler',
    budgetUtilization: 'Bütçe Kullanımı',
    creditSpending: 'Kredi Harcaması',
    activeSubscriptions: 'aktif abonelik',
    categoriesOverBudget: 'bütçe aşımı olan kategori',
    thisPeriod: 'Bu dönem'
  },
  charts: {
    expensesByCategory: 'Kategorilere Göre Giderler',
    income: 'Gelir',
    expenses: 'Gider',
    debt: 'Borç',
    incomeVsExpenses: 'Gelir ve Gider Trendi'
  }
};
