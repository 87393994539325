import React, { useState, useMemo } from 'react';
import { User, UserPlus, Mail, Shield, Ban, Trash2, KeyRound, ArrowUpDown } from 'lucide-react';
import { User as UserType } from '../../types/user';
import AdminUserModal from '../../components/admin/AdminUserModal';
import { useAuth } from '../../context/AuthContext';
import { USER_ROLES, USER_STATUSES } from '../../constants/users';

type SortField = 'name' | 'email' | 'role' | 'status' | 'plan' | 'lastLoginAt' | 'validUntil';
type SortDirection = 'asc' | 'desc';

const formatDate = (date: string | undefined) => {
  if (!date) return 'N/A';
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

const SortButton: React.FC<{
  field: SortField;
  currentField: SortField;
  direction: SortDirection;
  onSort: (field: SortField) => void;
  children: React.ReactNode;
}> = ({ field, currentField, direction, onSort, children }) => (
  <button
    onClick={() => onSort(field)}
    className="group inline-flex items-center space-x-1"
  >
    <span>{children}</span>
    <ArrowUpDown className={`h-4 w-4 ${field === currentField ? 'opacity-100' : 'opacity-0 group-hover:opacity-50'} ${
      field === currentField && direction === 'desc' ? 'transform rotate-180' : ''
    }`} />
  </button>
);

const AdminUsers = () => {
  const { user: currentUser, users = [], isLoading, error, updateUser, deleteUser, resetUserPassword } = useAuth();
  const [searchQuery, setSearchQuery] = useState('');
  const [roleFilter, setRoleFilter] = useState<'all' | UserType['role']>('all');
  const [statusFilter, setStatusFilter] = useState<'all' | UserType['status']>('all');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState<UserType | undefined>(undefined);
  const [sortField, setSortField] = useState<SortField>('name');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(current => current === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const sortedAndFilteredUsers = useMemo(() => {
    const filtered = users.filter((user) => {
      const matchesSearch = user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                           user.email.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesRole = roleFilter === 'all' || user.role === roleFilter;
      const matchesStatus = statusFilter === 'all' || user.status === statusFilter;
      return matchesSearch && matchesRole && matchesStatus;
    });

    return [...filtered].sort((a, b) => {
      let comparison = 0;
      switch (sortField) {
        case 'name':
          comparison = a.name.localeCompare(b.name);
          break;
        case 'email':
          comparison = a.email.localeCompare(b.email);
          break;
        case 'role':
          comparison = a.role.localeCompare(b.role);
          break;
        case 'status':
          comparison = a.status.localeCompare(b.status);
          break;
        case 'plan':
          comparison = a.subscription.plan.localeCompare(b.subscription.plan);
          break;
        case 'lastLoginAt':
          comparison = (a.lastLoginAt || '').localeCompare(b.lastLoginAt || '');
          break;
        case 'validUntil':
          const aDate = a.subscription.validUntil || '';
          const bDate = b.subscription.validUntil || '';
          comparison = aDate.localeCompare(bDate);
          break;
      }
      return sortDirection === 'asc' ? comparison : -comparison;
    });
  }, [users, searchQuery, roleFilter, statusFilter, sortField, sortDirection]);

  const handleAddUser = (userData: Omit<UserType, 'id' | 'createdAt'>) => {
    // TODO: Implement adding a new user
  };

  const handleUpdateUser = (userId: string, updates: Partial<UserType>) => {
    updateUser(userId, updates);
    setIsModalOpen(false);
  };

  const handleStatusChange = (userId: string, status: UserType['status']) => {
    handleUpdateUser(userId, { status });
  };

  const handleRoleChange = (userId: string, role: UserType['role']) => {
    handleUpdateUser(userId, { role });
  };

  const handleDeleteUser = (userId: string) => {
    if (window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
      deleteUser(userId);
    }
  };

  const handleResetPassword = (email: string) => {
    if (window.confirm(`Are you sure you want to send a password reset email to ${email}?`)) {
      resetUserPassword(email);
    }
  };

  return (
    <div className="p-8">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Users</h1>
          <p className="text-gray-600 dark:text-gray-400">Manage system users and their permissions</p>
        </div>
        <button
          onClick={() => {
            setEditingUser(undefined);
            setIsModalOpen(true);
          }}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
        >
          <UserPlus className="h-4 w-4 mr-2" />
          Add User
        </button>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700">
        <div className="p-4 border-b border-gray-100 dark:border-gray-700">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1">
              <input
                type="text"
                placeholder="Search users..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
              />
            </div>
            <select
              value={roleFilter}
              onChange={(e) => setRoleFilter(e.target.value as typeof roleFilter)}
              className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
            >
              <option value="all">All Roles</option>
              <option value={USER_ROLES.USER}>User</option>
              <option value={USER_ROLES.ADMIN}>Admin</option>
              <option value={USER_ROLES.SUPERADMIN}>Super Admin</option>
            </select>
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value as typeof statusFilter)}
              className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
            >
              <option value="all">All Status</option>
              <option value={USER_STATUSES.ACTIVE}>Active</option>
              <option value={USER_STATUSES.SUSPENDED}>Suspended</option>
              <option value={USER_STATUSES.BANNED}>Banned</option>
            </select>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50 dark:bg-gray-700">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  <SortButton field="name" currentField={sortField} direction={sortDirection} onSort={handleSort}>
                    User
                  </SortButton>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  <SortButton field="role" currentField={sortField} direction={sortDirection} onSort={handleSort}>
                    Role
                  </SortButton>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  <SortButton field="status" currentField={sortField} direction={sortDirection} onSort={handleSort}>
                    Status
                  </SortButton>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  <SortButton field="plan" currentField={sortField} direction={sortDirection} onSort={handleSort}>
                    Plan
                  </SortButton>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  <SortButton field="validUntil" currentField={sortField} direction={sortDirection} onSort={handleSort}>
                    Premium Expiry
                  </SortButton>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  <SortButton field="lastLoginAt" currentField={sortField} direction={sortDirection} onSort={handleSort}>
                    Last Login
                  </SortButton>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 dark:divide-gray-700">
              {sortedAndFilteredUsers.map((user) => (
                <tr key={user.id} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <div className="h-10 w-10 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
                          <User className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                        </div>
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900 dark:text-white">{user.name}</div>
                        <div className="flex items-center">
                          <Mail className="h-4 w-4 text-gray-400 mr-1" />
                          <div className="text-sm text-gray-500 dark:text-gray-400">{user.email}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <select
                      value={user.role}
                      onChange={e => handleRoleChange(user.id, e.target.value as UserType['role'])}
                      className="text-sm border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                    >
                      <option value={USER_ROLES.USER}>User</option>
                      <option value={USER_ROLES.ADMIN}>Admin</option>
                      <option value={USER_ROLES.SUPERADMIN}>Super Admin</option>
                    </select>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <select
                      value={user.status}
                      onChange={e => handleStatusChange(user.id, e.target.value as UserType['status'])}
                      className={`text-sm border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 ${
                        user.status === USER_STATUSES.ACTIVE ? 'text-green-600 dark:text-green-400' :
                        user.status === USER_STATUSES.SUSPENDED ? 'text-amber-600 dark:text-amber-400' :
                        'text-red-600 dark:text-red-400'
                      }`}
                    >
                      <option value={USER_STATUSES.ACTIVE}>Active</option>
                      <option value={USER_STATUSES.SUSPENDED}>Suspended</option>
                      <option value={USER_STATUSES.BANNED}>Banned</option>
                    </select>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      user.subscription.plan === 'premium'
                        ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                        : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300'
                    }`}>
                      {user.subscription.plan.charAt(0).toUpperCase() + user.subscription.plan.slice(1)}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {formatDate(user.subscription.validUntil)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {formatDate(user.lastLoginAt)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => {
                          setEditingUser(user);
                          setIsModalOpen(true);
                        }}
                        className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
                        title="Edit User"
                      >
                        <Shield className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => handleResetPassword(user.email)}
                        className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
                        title="Reset Password"
                      >
                        <KeyRound className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => handleStatusChange(user.id, user.status === USER_STATUSES.ACTIVE ? USER_STATUSES.BANNED : USER_STATUSES.ACTIVE)}
                        className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
                        title={user.status === USER_STATUSES.ACTIVE ? 'Ban User' : 'Activate User'}
                      >
                        <Ban className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => handleDeleteUser(user.id)}
                        className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg text-red-500 hover:text-red-700"
                        title="Delete User"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <AdminUserModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditingUser(undefined);
        }}
        onSubmit={editingUser ? 
          (data) => handleUpdateUser(editingUser.id, data) : 
          handleAddUser
        }
        initialData={editingUser}
      />
    </div>
  );
};

export default AdminUsers;
