export const budgetDetailsPage = {
  title: 'Budget Details',
  overview: 'Overview',
  totalBudget: 'Total Budget',
  spent: 'Spent',
  remaining: 'Remaining',
  transactions: 'Transactions',
  noTransactions: 'No transactions found',
  categories: 'Categories',
  noCategoriesFound: 'No categories found',
  filters: {
    dateRange: 'Date Range',
    category: 'Category',
    type: 'Transaction Type'
  },
  transactionTypes: {
    expense: 'Expense',
    income: 'Income'
  },
  budgetStatus: {
    onTrack: 'On Track',
    overBudget: 'Over Budget',
    nearingLimit: 'Nearing Limit'
  },
  actions: {
    editBudget: 'Edit Budget',
    deleteBudget: 'Delete Budget',
    addTransaction: 'Add Transaction'
  },
  insights: {
    title: 'Budget Insights',
    topCategories: 'Top Spending Categories',
    monthlyTrend: 'Monthly Spending Trend'
  },
  wallets: 'Wallets',
  allWallets: 'All wallets',
  allCategories: 'All categories'
};
