import React, { useState } from 'react';
import { Mail, Edit2, Eye, Save, AlertTriangle } from 'lucide-react';

interface EmailTemplate {
  id: string;
  name: string;
  subject: string;
  content: string;
  variables: string[];
  lastModified: string;
}

const AdminEmails = () => {
  const [templates, setTemplates] = useState<EmailTemplate[]>([
    {
      id: 'welcome',
      name: 'Welcome Email',
      subject: 'Welcome to Spendee!',
      content: `Hi {{name}},\n\nWelcome to Spendee! We're excited to help you manage your finances better.\n\nBest regards,\nThe Spendee Team`,
      variables: ['name'],
      lastModified: '2024-03-19'
    },
    {
      id: 'verification',
      name: 'Email Verification',
      subject: 'Verify your email address',
      content: `Hi {{name}},\n\nPlease verify your email address by clicking the link below:\n\n{{verificationLink}}\n\nBest regards,\nThe Spendee Team`,
      variables: ['name', 'verificationLink'],
      lastModified: '2024-03-19'
    },
    {
      id: 'password-reset',
      name: 'Password Reset',
      subject: 'Reset your password',
      content: `Hi {{name}},\n\nYou requested to reset your password. Click the link below to set a new password:\n\n{{resetLink}}\n\nBest regards,\nThe Spendee Team`,
      variables: ['name', 'resetLink'],
      lastModified: '2024-03-19'
    },
    {
      id: 'subscription-renewal',
      name: 'Subscription Renewal',
      subject: 'Your subscription is about to renew',
      content: `Hi {{name}},\n\nYour subscription will renew on {{renewalDate}}. The amount of {{amount}} will be charged to your card ending in {{lastFourDigits}}.\n\nBest regards,\nThe Spendee Team`,
      variables: ['name', 'renewalDate', 'amount', 'lastFourDigits'],
      lastModified: '2024-03-19'
    }
  ]);

  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const filteredTemplates = templates.filter(template =>
    template.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSave = () => {
    if (selectedTemplate) {
      setTemplates(current =>
        current.map(template =>
          template.id === selectedTemplate.id
            ? { ...selectedTemplate, lastModified: new Date().toISOString() }
            : template
        )
      );
      setEditMode(false);
    }
  };

  const getPreviewContent = (template: EmailTemplate) => {
    let content = template.content;
    template.variables.forEach(variable => {
      content = content.replace(
        new RegExp(`{{${variable}}}`, 'g'),
        `<span class="text-indigo-600 dark:text-indigo-400">[${variable}]</span>`
      );
    });
    return content;
  };

  return (
    <div className="p-8">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Email Templates</h1>
          <p className="text-gray-600 dark:text-gray-400">Manage system email templates and notifications</p>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Templates List */}
        <div className="lg:col-span-1">
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700">
            <div className="p-4 border-b border-gray-100 dark:border-gray-700">
              <input
                type="text"
                placeholder="Search templates..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
              />
            </div>
            <div className="divide-y divide-gray-100 dark:divide-gray-700">
              {filteredTemplates.map(template => (
                <button
                  key={template.id}
                  onClick={() => {
                    setSelectedTemplate(template);
                    setEditMode(false);
                    setPreviewMode(false);
                  }}
                  className={`w-full p-4 text-left hover:bg-gray-50 dark:hover:bg-gray-700 ${
                    selectedTemplate?.id === template.id ? 'bg-indigo-50 dark:bg-indigo-900/50' : ''
                  }`}
                >
                  <div className="flex items-center gap-3">
                    <div className="p-2 bg-indigo-100 dark:bg-indigo-900/50 rounded-lg">
                      <Mail className="h-5 w-5 text-indigo-600 dark:text-indigo-400" />
                    </div>
                    <div>
                      <h3 className="text-sm font-medium text-gray-900 dark:text-white">{template.name}</h3>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        Last modified: {new Date(template.lastModified).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Template Editor */}
        <div className="lg:col-span-2">
          {selectedTemplate ? (
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700">
              <div className="p-4 border-b border-gray-100 dark:border-gray-700 flex items-center justify-between">
                <h2 className="text-lg font-semibold text-gray-900 dark:text-white">{selectedTemplate.name}</h2>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => setPreviewMode(!previewMode)}
                    className={`p-2 rounded-lg ${
                      previewMode
                        ? 'bg-indigo-100 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400'
                        : 'hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-500 dark:text-gray-400'
                    }`}
                  >
                    <Eye className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => setEditMode(!editMode)}
                    className={`p-2 rounded-lg ${
                      editMode
                        ? 'bg-indigo-100 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400'
                        : 'hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-500 dark:text-gray-400'
                    }`}
                  >
                    <Edit2 className="h-5 w-5" />
                  </button>
                  {editMode && (
                    <button
                      onClick={handleSave}
                      className="p-2 rounded-lg bg-green-100 dark:bg-green-900/50 text-green-600 dark:text-green-400"
                    >
                      <Save className="h-5 w-5" />
                    </button>
                  )}
                </div>
              </div>

              <div className="p-4">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Subject
                  </label>
                  <input
                    type="text"
                    value={selectedTemplate.subject}
                    onChange={e => setSelectedTemplate(prev => prev ? { ...prev, subject: e.target.value } : null)}
                    disabled={!editMode}
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white disabled:bg-gray-50 dark:disabled:bg-gray-800 disabled:text-gray-500"
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Content
                  </label>
                  {previewMode ? (
                    <div
                      className="prose dark:prose-invert max-w-none p-4 bg-gray-50 dark:bg-gray-900 rounded-lg"
                      dangerouslySetInnerHTML={{
                        __html: getPreviewContent(selectedTemplate).replace(/\n/g, '<br />')
                      }}
                    />
                  ) : (
                    <textarea
                      value={selectedTemplate.content}
                      onChange={e => setSelectedTemplate(prev => prev ? { ...prev, content: e.target.value } : null)}
                      disabled={!editMode}
                      rows={12}
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white disabled:bg-gray-50 dark:disabled:bg-gray-800 disabled:text-gray-500"
                    />
                  )}
                </div>

                <div className="bg-amber-50 dark:bg-amber-900/30 rounded-lg p-4">
                  <div className="flex items-start gap-3">
                    <AlertTriangle className="h-5 w-5 text-amber-600 dark:text-amber-400 mt-0.5" />
                    <div>
                      <h4 className="text-sm font-medium text-amber-800 dark:text-amber-300">Available Variables</h4>
                      <div className="mt-1 flex flex-wrap gap-2">
                        {selectedTemplate.variables.map(variable => (
                          <span
                            key={variable}
                            className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-amber-100 dark:bg-amber-900 text-amber-700 dark:text-amber-300"
                          >
                            {`{{${variable}}}`}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 p-8 text-center">
              <Mail className="h-12 w-12 text-gray-400 dark:text-gray-500 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">Select a template</h3>
              <p className="text-gray-500 dark:text-gray-400">
                Choose a template from the list to view or edit its content
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminEmails;