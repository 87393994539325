import { creditCardsPage as en } from './en';
import { creditCardsPage as tr } from './tr';

export type CreditCardsPageTranslations = typeof en;

export const translations = {
  en,
  tr
} as const;

// Type guard to check if a language is supported
export const isSupportedLanguage = (lang: string): lang is keyof typeof translations => {
  return lang in translations;
};

// Helper to get translations for a specific language
export const getTranslations = (lang: string): CreditCardsPageTranslations => {
  if (isSupportedLanguage(lang)) {
    return translations[lang];
  }
  return translations.en; // Fallback to English
};
