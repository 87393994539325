export const categoriesPage = {
  title: 'Categories',
  subtitle: 'Manage your transaction categories',
  noCategories: 'No categories found',
  addCategoryButton: 'Add Category',
  filters: {
    type: 'Category Type',
    parent: 'Parent Category'
  },
  categoryTypes: {
    expense: 'Expense Categories',
    income: 'Income Categories',
    neutral: 'Neutral'
  },
  actions: {
    edit: 'Edit',
    delete: 'Delete',
    deleteSelected: 'Delete Selected',
    merge: 'Merge Categories',
    selectTarget: 'Select target category'
  },
  details: {
    totalSpent: 'Total Spent',
    transactions: 'transaction',
    transactionsIn: 'in',
    noTransactions: 'No transactions found'
  },
  icons: {
    select: 'Select Icon',
    search: 'Search icons'
  },
  validation: {
    nameRequired: 'Category name is required',
    typeRequired: 'Category type is required'
  }
};
