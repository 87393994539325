import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAuth } from '../context/AuthContext';
import { useNotification } from '../context/NotificationContext';
import { getTranslations } from '../locales/pages/acceptInvitationPage';

interface AcceptInvitationPageProps {
  language?: 'en' | 'tr';
}

const AcceptInvitationPage: React.FC<AcceptInvitationPageProps> = ({ language = 'en' }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const translations = getTranslations(language);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const acceptInvitation = async () => {
      const invitationId = searchParams.get('id');
      
      if (!invitationId) {
        showNotification('error', translations.errors.invitationNotFound);
        navigate('/');
        return;
      }

      if (!user) {
        // If user is not logged in, redirect to login page with return URL
        navigate(`/login?returnUrl=${encodeURIComponent(`/accept-invitation?id=${invitationId}`)}`);
        return;
      }

      setIsProcessing(true);

      try {
        const functions = getFunctions();
        const acceptWalletInvitation = httpsCallable(functions, 'acceptWalletInvitation');
        
        await acceptWalletInvitation({
          invitationId,
          userId: user.id
        });

        showNotification('success', translations.status.accepted);
        navigate('/wallets');
      } catch (error) {
        console.error('Error accepting invitation:', error);
        showNotification('error', translations.errors.invitationNotFound);
        navigate('/');
      } finally {
        setIsProcessing(false);
      }
    };

    acceptInvitation();
  }, [user, searchParams, navigate, showNotification, translations]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
      <div className="max-w-md w-full space-y-8 p-8 bg-white dark:bg-gray-800 rounded-xl shadow-lg">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
            {translations.title}
          </h2>
          {isProcessing && (
            <div className="mt-4">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600 mx-auto"></div>
              <p className="mt-4 text-gray-500 dark:text-gray-400">
                {translations.status.pending}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AcceptInvitationPage;
