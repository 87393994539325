import React, { useState } from 'react';
import { Tag, Plus, Search, Trash2, Check, X } from 'lucide-react';
import { useAdmin } from '../../context/AdminContext';
import { Label } from '../../types';

const AdminLabels = () => {
  const { labels, addLabel, deleteLabel } = useAdmin();
  const [searchQuery, setSearchQuery] = useState('');
  const [newLabel, setNewLabel] = useState({ name: '', color: 'indigo' });
  const [isAddingLabel, setIsAddingLabel] = useState(false);

  const filteredLabels = labels?.filter(label =>
    label.name.toLowerCase().includes(searchQuery.toLowerCase())
  ) || [];

  const labelStats = filteredLabels.reduce((acc, label) => ({
    ...acc,
    [label.id]: {
      userCount: label.transactionCount || 0,
      percentage: ((label.transactionCount || 0) / (labels?.length || 1) * 100).toFixed(1)
    }
  }), {});

  const handleAddLabel = () => {
    if (newLabel.name.trim()) {
      addLabel({
        name: newLabel.name.trim(),
        color: newLabel.color,
        transactionCount: 0
      });
      setNewLabel({ name: '', color: 'indigo' });
      setIsAddingLabel(false);
    }
  };

  const colors = [
    { name: 'indigo', class: 'bg-indigo-500' },
    { name: 'emerald', class: 'bg-emerald-500' },
    { name: 'amber', class: 'bg-amber-500' },
    { name: 'rose', class: 'bg-rose-500' },
    { name: 'violet', class: 'bg-violet-500' },
    { name: 'blue', class: 'bg-blue-500' }
  ];

  return (
    <div className="p-8">
      <div className="mb-6">
        <div className="flex items-center justify-between mb-4">
          <div>
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Default Labels</h1>
            <p className="text-gray-600 dark:text-gray-400">Manage system-wide default labels</p>
          </div>
          <button
            onClick={() => setIsAddingLabel(true)}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            Add Default Label
          </button>
        </div>

        <div className="relative">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
          <input
            type="text"
            placeholder="Search labels..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
          />
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 shadow-sm border border-gray-200 dark:border-gray-700 rounded-lg">
        {isAddingLabel && (
          <div className="p-4 border-b border-gray-200 dark:border-gray-700">
            <div className="space-y-4">
              <input
                type="text"
                value={newLabel.name}
                onChange={(e) => setNewLabel(prev => ({ ...prev, name: e.target.value }))}
                placeholder="Label name"
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
              />
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Color
                </label>
                <div className="grid grid-cols-6 gap-2">
                  {colors.map(color => (
                    <button
                      key={color.name}
                      type="button"
                      onClick={() => setNewLabel(prev => ({ ...prev, color: color.name }))}
                      className={`w-8 h-8 rounded-full ${color.class} ${
                        newLabel.color === color.name ? 'ring-2 ring-offset-2 ring-indigo-500 dark:ring-offset-gray-800' : ''
                      }`}
                      title={color.name}
                    />
                  ))}
                </div>
              </div>
              <div className="flex justify-end gap-2">
                <button
                  onClick={() => setIsAddingLabel(false)}
                  className="px-3 py-1.5 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  Cancel
                </button>
                <button
                  onClick={handleAddLabel}
                  className="px-3 py-1.5 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
                >
                  Add Label
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="divide-y divide-gray-200 dark:divide-gray-700">
          {filteredLabels.map(label => (
            <div
              key={label.id}
              className="flex items-center justify-between p-4 hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              <div className="flex items-center gap-3">
                <div className={`p-2 rounded-lg bg-${label.color}-100 dark:bg-${label.color}-900/50`}>
                  <Tag className={`h-5 w-5 text-${label.color}-600 dark:text-${label.color}-400`} />
                </div>
                <div>
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">{label.name}</h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Used by {labelStats[label.id]?.userCount || 0} users ({labelStats[label.id]?.percentage || 0}%)
                  </p>
                </div>
              </div>
              <button
                onClick={() => deleteLabel(label.id)}
                className="p-2 text-gray-400 hover:text-red-600 dark:hover:text-red-400 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <Trash2 className="h-5 w-5" />
              </button>
            </div>
          ))}

          {filteredLabels.length === 0 && (
            <div className="p-8 text-center text-gray-500 dark:text-gray-400">
              {searchQuery ? 'No labels found matching your search' : 'No default labels defined yet'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminLabels;