export const creditCardsPage = {
  title: 'Kredi Kartları',
  subtitle: 'Kredi kartlarınızı yönetin ve bakiyeleri takip edin',
  new: 'Yeni Kredi Kartı',
  noCreditCards: 'Kredi kartı bulunamadı',
  addCreditCardButton: 'Kredi Kartı Ekle',
  totalLimit: 'Toplam Limit',
  availableLimit: 'Kullanılabilir Limit',
  usedLimit: 'Kullanılan Limit',
  filters: {
    status: 'Durum',
    bank: 'Banka',
    type: 'Kart Tipi'
  },
  statuses: {
    active: 'Aktif',
    blocked: 'Bloke',
    expired: 'Süresi Dolmuş'
  },
  cardTypes: {
    credit: 'Kredi Kartı',
    debit: 'Banka Kartı',
    prepaid: 'Ön Ödemeli Kart'
  },
  fields: {
    cardNumber: 'Kart Numarası',
    validThru: 'Son Kullanma',
    expiryDate: 'Son Kullanma Tarihi',
    limit: 'Limit',
    balance: 'Bakiye',
    available: 'Kullanılabilir'
  }
};
