import React, { useState } from 'react';
import { Plus, ArrowUpRight, ArrowDownRight, Wallet as WalletIcon, Users } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useWallets } from '../context/WalletContext';
import { useTransactions } from '../context/TransactionContext';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
import WalletModal from '../components/wallets/WalletModal';
import ExportButton from '../components/ExportButton';
import { exportWallets } from '../utils/export';
import { Wallet } from '../types';
import { checkPlanLimit } from '../utils/planLimits';
import PlanLimitModal from '../components/PlanLimitModal';

const WalletsPage = () => {
  const navigate = useNavigate();
  const { wallets, addWallet } = useWallets();
  const { transactions } = useTransactions();
  const { user } = useAuth();
  const { t } = useLanguage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPlanLimitModal, setShowPlanLimitModal] = useState(false);

  const getWalletStats = (walletId: string) => {
    const walletTransactions = transactions.filter(t => t.walletId === walletId);
    const income = walletTransactions
      .filter(t => t.amount > 0)
      .reduce((sum, t) => sum + t.amount, 0);
    const expenses = walletTransactions
      .filter(t => t.amount < 0)
      .reduce((sum, t) => sum + Math.abs(t.amount), 0);
    const transactionCount = walletTransactions.length;

    return { income, expenses, transactionCount };
  };

  const handleAddWalletClick = () => {
    const canAddWallet = checkPlanLimit(
      user?.subscription?.plan || 'free',
      'wallets',
      wallets.length
    );

    if (!canAddWallet) {
      setShowPlanLimitModal(true);
      return;
    }

    setIsModalOpen(true);
  };

  const handleAddWallet = (data: Omit<Wallet, 'id'>) => {
    addWallet(data);
    setIsModalOpen(false);
  };

  const handleExport = (format: 'excel' | 'json' | 'pdf') => {
    exportWallets(wallets, format);
  };

  return (
    <div className="p-8">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            {t('pages.walletsPage.title')}
          </h1>
          <p className="text-gray-600 dark:text-gray-400">
            {t('pages.walletsPage.subtitle')}
          </p>
        </div>
        <div className="flex items-center gap-4">
          <ExportButton onExport={handleExport} />
          <button
            onClick={handleAddWalletClick}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            {t('pages.walletsPage.addWalletButton')}
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {wallets.map(wallet => {
          const stats = getWalletStats(wallet.id);
          return (
            <div
              key={wallet.id}
              className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 p-6 hover:shadow-md transition-shadow cursor-pointer"
              onClick={() => navigate(`/wallets/${wallet.id}`)}
            >
              <div className="flex items-center justify-between mb-4">
                <div className={`p-3 rounded-lg bg-${wallet.color}-100 dark:bg-${wallet.color}-900`}>
                  <WalletIcon className={`h-6 w-6 text-${wallet.color}-600 dark:text-${wallet.color}-400`} />
                </div>
                <div className="flex items-center gap-2">
                  {wallet.members && Object.keys(wallet.members).length > 0 && (
                    <div className="flex items-center gap-1 px-2 py-1 rounded-full bg-gray-100 dark:bg-gray-700">
                      <Users className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                      <span className="text-xs text-gray-600 dark:text-gray-300">
                        {Object.keys(wallet.members).length + 1}
                      </span>
                    </div>
                  )}
                  <span className={`px-3 py-1 rounded-full text-sm font-medium bg-${wallet.color}-100 dark:bg-${wallet.color}-900 text-${wallet.color}-700 dark:text-${wallet.color}-300`}>
                    {wallet.currency}
                  </span>
                </div>
              </div>

              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">{wallet.name}</h3>
              <p className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
                {wallet.balance.toLocaleString('en-US', {
                  style: 'currency',
                  currency: wallet.currency
                })}
              </p>

              <div className="space-y-3">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center gap-2 text-green-600 dark:text-green-400">
                    <ArrowUpRight className="h-4 w-4" />
                    {t('pages.walletsPage.stats.income')}
                  </div>
                  <span className="font-medium text-green-600 dark:text-green-400">
                    {stats.income.toLocaleString('en-US', {
                      style: 'currency',
                      currency: wallet.currency
                    })}
                  </span>
                </div>
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center gap-2 text-red-600 dark:text-red-400">
                    <ArrowDownRight className="h-4 w-4" />
                    {t('pages.walletsPage.stats.expenses')}
                  </div>
                  <span className="font-medium text-red-600 dark:text-red-400">
                    {stats.expenses.toLocaleString('en-US', {
                      style: 'currency',
                      currency: wallet.currency
                    })}
                  </span>
                </div>
              </div>

              <div className="mt-6 pt-4 border-t border-gray-100 dark:border-gray-700">
                <div className="flex items-center justify-between text-sm">
                  <span className="text-gray-500 dark:text-gray-400">
                    {stats.transactionCount} {t('pages.walletsPage.details.transactions')}
                  </span>
                  <span className="text-indigo-600 dark:text-indigo-400 font-medium">
                    {t('pages.walletsPage.details.viewDetails')} →
                  </span>
                </div>
              </div>
            </div>
          );
        })}

        {checkPlanLimit(user?.subscription?.plan || 'free', 'wallets', wallets.length) && (
          <button
            onClick={handleAddWalletClick}
            className="flex flex-col items-center justify-center p-6 border-2 border-dashed border-gray-200 dark:border-gray-700 rounded-xl hover:border-indigo-500 dark:hover:border-indigo-400 hover:bg-indigo-50 dark:hover:bg-indigo-900/50 transition-colors"
          >
            <div className="w-12 h-12 rounded-full bg-indigo-100 dark:bg-indigo-900 flex items-center justify-center mb-3">
              <Plus className="h-6 w-6 text-indigo-600 dark:text-indigo-400" />
            </div>
            <span className="text-sm font-medium text-gray-900 dark:text-white">
              {t('pages.walletsPage.addNew.title')}
            </span>
            <span className="text-xs text-gray-500 dark:text-gray-400">
              {t('pages.walletsPage.addNew.description')}
            </span>
          </button>
        )}
      </div>

      <WalletModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleAddWallet}
      />

      <PlanLimitModal
        isOpen={showPlanLimitModal}
        onClose={() => setShowPlanLimitModal(false)}
        feature="wallets"
      />
    </div>
  );
};

export default WalletsPage;
