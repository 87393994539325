import React, { useState, useMemo, useEffect } from 'react';
import { Plus } from 'lucide-react';
import { useTransactions } from '../context/TransactionContext';
import { useWallets } from '../context/WalletContext';
import { useCategories } from '../context/CategoryContext';
import { useLabels } from '../context/LabelContext';
import { useAuth } from '../context/AuthContext';
import { useDateFilter } from '../context/DateFilterContext';
import { useLanguage } from '../context/LanguageContext';
import { Transaction } from '../types';
import TransactionModal from '../components/transactions/TransactionModal';
import TransactionDetails from '../components/transactions/TransactionDetails';
import ExportButton from '../components/ExportButton';
import DateRangePicker from '../components/reports/DateRangePicker';
import { exportTransactions } from '../utils/export';
import ImagePreviewModal from '../components/transactions/ImagePreviewModal';
import TransactionPageFilters from '../components/transactions/TransactionPageFilters';
import TransactionPageList from '../components/transactions/TransactionPageList';
import TransactionPageHeader from '../components/transactions/TransactionPageHeader';

type FilterType = 'all' | 'income' | 'expense';

const TransactionsPage: React.FC = () => {
  const { transactions, filterTransactions, deleteTransaction, addTransaction, updateTransaction } = useTransactions();
  const { wallets } = useWallets();
  const { categories } = useCategories();
  const { labels } = useLabels();
  const { user } = useAuth();
  const { t } = useLanguage();
  const { dateRange, setDateRange } = useDateFilter();

  // Filter states
  const [filterType, setFilterType] = useState<FilterType>('all');
  const [filterCategory, setFilterCategory] = useState<string>('all');
  const [filterLabel, setFilterLabel] = useState<string>('all');
  const [filterWallet, setFilterWallet] = useState<string | 'all'>('all');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  // UI states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState<string[]>([]);
  const [editingTransaction, setEditingTransaction] = useState<Transaction | null>(null);
  const [viewingTransaction, setViewingTransaction] = useState<Transaction | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  // Reset category filter when transaction type changes
  useEffect(() => {
    setFilterCategory('all');
  }, [filterType]);

  const filteredTransactions = useMemo(() => {
    return filterTransactions(
      {
        ...(filterType !== 'all' ? { type: filterType } : {}),
        ...(filterWallet !== 'all' ? { walletId: filterWallet } : {})
      }
    )
      .filter(t => {
        const transactionDate = new Date(t.date);
        const startDate = new Date(dateRange.start);
        const endDate = new Date(dateRange.end);
        return transactionDate >= startDate && transactionDate <= endDate;
      })
      .filter(t => filterCategory === 'all' || t.category === filterCategory)
      .filter(t => filterLabel === 'all' || (t.labels && t.labels.includes(filterLabel)))
      .sort((a, b) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
      });
  }, [transactions, filterType, filterWallet, filterCategory, filterLabel, sortOrder, dateRange, filterTransactions]);

  const handleSelectAll = () => {
    setSelectedTransactions(
      selectedTransactions.length === filteredTransactions.length
        ? []
        : filteredTransactions.map(t => t.id)
    );
  };

  const handleDeleteSelected = () => {
    selectedTransactions.forEach(id => deleteTransaction(id));
    setSelectedTransactions([]);
  };

  const handleAddTransaction = async (data: Omit<Transaction, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => {
    if (!user) return;
    const timestamp = new Date().toISOString();
    await addTransaction({
      ...data,
      userId: user.id,
      createdAt: timestamp,
      updatedAt: timestamp
    });
    setIsModalOpen(false);
  };

  const handleEditTransaction = async (data: Omit<Transaction, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => {
    if (!editingTransaction) return;
    const timestamp = new Date().toISOString();
    await updateTransaction(editingTransaction.id, {
      ...data,
      updatedAt: timestamp
    });
    setIsModalOpen(false);
    setEditingTransaction(null);
  };

  const handleExport = (format: 'excel' | 'json' | 'pdf') => {
    exportTransactions(filteredTransactions, format);
  };

  const getWalletInfo = (walletId: string) => {
    const wallet = wallets.find(w => w.id === walletId);
    return {
      name: wallet?.name || 'Unknown Wallet',
      color: wallet?.color || 'gray',
      currency: wallet?.currency || 'TRY'
    };
  };

  const getCategoryName = (categoryId: string): string => {
    const category = categories.find(c => c.id === categoryId);
    return category?.name || categoryId;
  };

  const getLabelInfo = (labelId: string) => {
    const label = labels.find(l => l.id === labelId);
    return {
      name: label?.name || labelId,
      color: label?.color || 'indigo'
    };
  };

  const handleAttachmentClick = (e: React.MouseEvent, url: string) => {
    e.preventDefault();
    e.stopPropagation();
    if (url.match(/\.(jpg|jpeg|png|gif|webp)$/i)) {
      setPreviewImage(url);
    } else {
      window.open(url, '_blank')?.focus();
    }
  };

  // Map wallets to ensure all required properties are present
  const mappedWallets = wallets.map(wallet => ({
    id: wallet.id,
    name: wallet.name || 'Unknown Wallet',
    color: wallet.color || 'gray'
  }));

  return (
    <div className="p-8">
      <div className="mb-6">
        <div className="flex items-center justify-between mb-4">
          <div>
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
              {t('pages.transactionsPage.title')}
            </h1>
            <p className="text-gray-600 dark:text-gray-400">
              {t('pages.transactionsPage.subtitle')}
            </p>
          </div>
          <div className="flex items-center gap-4">
            <DateRangePicker
              startDate={dateRange.start}
              endDate={dateRange.end}
              onChange={setDateRange}
            />
            <ExportButton onExport={handleExport} />
            <button
              onClick={() => setIsModalOpen(true)}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
            >
              <Plus className="h-4 w-4 mr-2" />
              {t('pages.transactionsPage.new')}
            </button>
          </div>
        </div>

        <TransactionPageFilters
          filterType={filterType}
          filterCategory={filterCategory}
          filterLabel={filterLabel}
          filterWallet={filterWallet}
          sortOrder={sortOrder}
          wallets={mappedWallets}
          onTypeChange={setFilterType}
          onCategoryChange={setFilterCategory}
          onLabelChange={setFilterLabel}
          onWalletChange={setFilterWallet}
          onSortOrderChange={() => setSortOrder(current => current === 'desc' ? 'asc' : 'desc')}
        />
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700">
        <TransactionPageHeader
          selectedCount={selectedTransactions.length}
          totalCount={filteredTransactions.length}
          onSelectAll={handleSelectAll}
          onDeleteSelected={handleDeleteSelected}
        />

        <TransactionPageList
          transactions={filteredTransactions}
          selectedTransactions={selectedTransactions}
          onSelect={(id) => {
            setSelectedTransactions(current =>
              current.includes(id)
                ? current.filter(tId => tId !== id)
                : [...current, id]
            );
          }}
          onEdit={(transaction) => {
            setEditingTransaction(transaction);
            setIsModalOpen(true);
          }}
          onView={setViewingTransaction}
          onAttachmentClick={handleAttachmentClick}
          getWalletInfo={getWalletInfo}
          getCategoryName={getCategoryName}
          getLabelInfo={getLabelInfo}
        />
      </div>

      <TransactionModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setTimeout(() => setEditingTransaction(null), 200);
        }}
        onSubmit={editingTransaction ? handleEditTransaction : handleAddTransaction}
        initialData={editingTransaction || undefined}
      />

      <TransactionDetails
        isOpen={!!viewingTransaction}
        onClose={() => setViewingTransaction(null)}
        transaction={viewingTransaction!}
      />

      <ImagePreviewModal
        isOpen={!!previewImage}
        onClose={() => setPreviewImage(null)}
        imageUrl={previewImage || ''}
      />
    </div>
  );
};

export default TransactionsPage;
