import React, { useState, useMemo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { TrendingUp, TrendingDown, DollarSign, Wallet, CreditCard, Calendar } from 'lucide-react';
import { useTransactions } from '../context/TransactionContext';
import { useWallets } from '../context/WalletContext';
import { useCreditCards } from '../context/CreditCardContext';
import { useSubscriptions } from '../context/SubscriptionContext';
import { useBudgets } from '../context/BudgetContext';
import { useLanguage } from '../context/LanguageContext';
import { useAuth } from '../context/AuthContext';
import { useCurrency } from '../context/CurrencyContext';
import { exportTransactions } from '../utils/export';
import ExportButton from '../components/ExportButton';
import DateRangePicker from '../components/reports/DateRangePicker';
import SummaryCard from '../components/reports/SummaryCard';
import TrendChart from '../components/reports/TrendChart';
import DistributionChart from '../components/reports/DistributionChart';
import { useCategories } from '../context/CategoryContext';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const ReportsPage = () => {
  const { categories } = useCategories();
  const { transactions } = useTransactions();
  const { wallets } = useWallets();
  const { creditCards } = useCreditCards();
  const { subscriptions } = useSubscriptions();
  const { budgets } = useBudgets();
  const { t } = useLanguage();
  const { formatAmount } = useCurrency();

  const [dateRange, setDateRange] = useState({
    start: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0],
    end: new Date().toISOString().split('T')[0]
  });

  const filteredTransactions = useMemo(() => {
    return transactions.filter(t => {
      const transactionDate = new Date(t.date);
      const startDate = new Date(dateRange.start);
      const endDate = new Date(dateRange.end);
      return transactionDate >= startDate && transactionDate <= endDate;
    });
  }, [transactions, dateRange]);

  const stats = useMemo(() => {
    const income = filteredTransactions
      .filter(t => t.type === 'income')
      .reduce((sum, t) => sum + t.amount, 0);
    const expenses = Math.abs(
      filteredTransactions
        .filter(t => t.type === 'expense')
        .reduce((sum, t) => sum + t.amount, 0)
    );
    const debt = Math.abs(
      filteredTransactions
        .filter(t => t.type === 'debt')
        .reduce((sum, t) => sum + t.amount, 0)
    );
    const balance = income - expenses - debt;
    const savingsRate = income > 0 ? ((income - expenses - debt) / income) * 100 : 0;

    return { income, expenses, debt, balance, savingsRate };
  }, [filteredTransactions]);

  const creditCardStats = useMemo(() => {
    const totalLimit = creditCards.reduce((sum, card) => sum + card.limit, 0);
    const totalBalance = creditCards.reduce((sum, card) => sum + card.balance, 0);
    const utilization = (totalBalance / totalLimit) * 100;
    const creditTransactions = filteredTransactions.filter(t => t.paymentMethod === 'credit');
    const creditSpending = Math.abs(creditTransactions.reduce((sum, t) => sum + t.amount, 0));

    return { totalLimit, totalBalance, utilization, creditSpending };
  }, [creditCards, filteredTransactions]);

  const subscriptionStats = useMemo(() => {
    const monthlyTotal = subscriptions.reduce((sum, sub) => {
      const monthlyAmount = sub.billingCycle === 'yearly' 
        ? sub.amount / 12 
        : sub.amount;
      return sum + monthlyAmount;
    }, 0);
    const byName = subscriptions.reduce((acc, sub) => {
      acc[sub.name] = (acc[sub.name] || 0) + sub.amount;
      return acc;
    }, {} as Record<string, number>);

    return { monthlyTotal, byName, count: subscriptions.length };
  }, [subscriptions]);

  const budgetStats = useMemo(() => {
    const totalBudgeted = budgets.reduce((sum, budget) => sum + budget.amount, 0);
    const totalSpent = budgets.reduce((sum, budget) => sum + budget.spent, 0);
    const overBudgetCount = budgets.filter(b => b.spent > b.amount).length;
    const utilization = (totalSpent / totalBudgeted) * 100;

    return { totalBudgeted, totalSpent, overBudgetCount, utilization };
  }, [budgets]);

  const handleExport = (format: 'excel' | 'json' | 'pdf') => {
    exportTransactions(filteredTransactions, format);
  };

  const trendData = useMemo(() => {
    const monthlyData: { [key: string]: { income: number; expenses: number; debt: number } } = {};
    
    filteredTransactions.forEach(transaction => {
      const date = new Date(transaction.date);
      const monthKey = date.toLocaleString('default', { month: 'short', year: 'numeric' });
      
      if (!monthlyData[monthKey]) {
        monthlyData[monthKey] = { income: 0, expenses: 0, debt: 0 };
      }
      
      if (transaction.type === 'income') {
        monthlyData[monthKey].income += transaction.amount;
      } else if (transaction.type === 'expense') {
        monthlyData[monthKey].expenses += Math.abs(transaction.amount);
      } else {
        monthlyData[monthKey].debt += Math.abs(transaction.amount);
      }
    });

    const labels = Object.keys(monthlyData);
    const incomeData = labels.map(key => monthlyData[key].income);
    const expensesData = labels.map(key => monthlyData[key].expenses);
    const debtData = labels.map(key => monthlyData[key].debt);

    return {
      labels,
      datasets: [
        {
          label: t('pages.reportsPage.charts.income'),
          data: incomeData,
          borderColor: '#10B981',
          backgroundColor: 'rgba(16, 185, 129, 0.1)',
          fill: true,
        },
        {
          label: t('pages.reportsPage.charts.expenses'),
          data: expensesData,
          borderColor: '#EF4444',
          backgroundColor: 'rgba(239, 68, 68, 0.1)',
          fill: true,
        },
        {
          label: t('pages.reportsPage.charts.debt'),
          data: debtData,
          borderColor: '#F59E0B',
          backgroundColor: 'rgba(245, 158, 11, 0.1)',
          fill: true,
        }
      ],
    };
  }, [filteredTransactions, t]);

  const categoryDistribution = useMemo(() => {
    const expensesByCategory: { [key: string]: number } = {};
    
    filteredTransactions
      .filter(t => t.type === 'expense')
      .forEach(transaction => {
        // Get category name instead of using ID
        const category = categories.find(c => c.id === transaction.category);
        const categoryName = category ? category.name : transaction.category;
        
        if (!expensesByCategory[categoryName]) {
          expensesByCategory[categoryName] = 0;
        }
        expensesByCategory[categoryName] += Math.abs(transaction.amount);
      });

    const sortedCategories = Object.entries(expensesByCategory)
      .sort(([, a], [, b]) => b - a);

    return {
      labels: sortedCategories.map(([category]) => category),
      datasets: [{
        data: sortedCategories.map(([, amount]) => amount),
        backgroundColor: [
          '#10B981', '#3B82F6', '#6366F1', '#8B5CF6', 
          '#EC4899', '#EF4444', '#F59E0B', '#10B981'
        ],
      }],
    };
  }, [filteredTransactions, categories]);

  const totalExpenses = useMemo(() => 
    Math.abs(filteredTransactions
      .filter(t => t.type === 'expense')
      .reduce((sum, t) => sum + t.amount, 0)
    ),
    [filteredTransactions]
  );

  return (
    <div className="p-8">
     <div className="flex items-center justify-between mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            {t('pages.reportsPage.title')}
          </h1>
          <p className="text-gray-600 dark:text-gray-400">
            {t('pages.reportsPage.subtitle')}
          </p>
        </div>
        <div className="flex items-center gap-4">
          <DateRangePicker
            startDate={dateRange.start}
            endDate={dateRange.end}
            onChange={setDateRange}
          />
          <ExportButton onExport={handleExport} />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <SummaryCard
          title={t('pages.reportsPage.summaryCards.totalBalance')}
          value={formatAmount(stats.balance)}
          icon={DollarSign}
          iconBgColor="bg-indigo-100 dark:bg-indigo-900/50"
          iconColor="text-indigo-600 dark:text-indigo-400"
        />
        <SummaryCard
          title={t('pages.reportsPage.summaryCards.totalIncome')}
          value={formatAmount(stats.income)}
          icon={TrendingUp}
          iconBgColor="bg-green-100 dark:bg-green-900/50"
          iconColor="text-green-600 dark:text-green-400"
          valueColor="text-green-600 dark:text-green-400"
        />
        <SummaryCard
          title={t('pages.reportsPage.summaryCards.totalExpenses')}
          value={formatAmount(stats.expenses)}
          icon={TrendingDown}
          iconBgColor="bg-red-100 dark:bg-red-900/50"
          iconColor="text-red-600 dark:text-red-400"
          valueColor="text-red-600 dark:text-red-400"
        />
        <SummaryCard
          title={t('pages.reportsPage.summaryCards.savingsRate')}
          value={`${stats.savingsRate.toFixed(1)}%`}
          icon={Wallet}
          iconBgColor="bg-amber-100 dark:bg-amber-900/50"
          iconColor="text-amber-600 dark:text-amber-400"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <SummaryCard
          title={t('pages.reportsPage.summaryCards.creditUtilization')}
          value={`${creditCardStats.utilization.toFixed(1)}%`}
          icon={CreditCard}
          iconBgColor="bg-purple-100 dark:bg-purple-900/50"
          iconColor="text-purple-600 dark:text-purple-400"
          subtitle={`${formatAmount(creditCardStats.totalBalance)} / ${formatAmount(creditCardStats.totalLimit)}`}
        />
        <SummaryCard
          title={t('pages.reportsPage.summaryCards.monthlySubscriptions')}
          value={formatAmount(subscriptionStats.monthlyTotal)}
          icon={Calendar}
          iconBgColor="bg-blue-100 dark:bg-blue-900/50"
          iconColor="text-blue-600 dark:text-blue-400"
          subtitle={`${subscriptionStats.count} ${t('pages.reportsPage.summaryCards.activeSubscriptions')}`}
        />
        <SummaryCard
          title={t('pages.reportsPage.summaryCards.budgetUtilization')}
          value={`${budgetStats.utilization.toFixed(1)}%`}
          icon={DollarSign}
          iconBgColor="bg-emerald-100 dark:bg-emerald-900/50"
          iconColor="text-emerald-600 dark:text-emerald-400"
          subtitle={`${budgetStats.overBudgetCount} ${t('pages.reportsPage.summaryCards.categoriesOverBudget')}`}
        />
        <SummaryCard
          title={t('pages.reportsPage.summaryCards.creditSpending')}
          value={formatAmount(creditCardStats.creditSpending)}
          icon={CreditCard}
          iconBgColor="bg-rose-100 dark:bg-rose-900/50"
          iconColor="text-rose-600 dark:text-rose-400"
          subtitle={t('pages.reportsPage.summaryCards.thisPeriod')}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
        <div className="lg:col-span-2">
          <TrendChart 
            data={trendData}
            title={t('pages.reportsPage.charts.incomeVsExpenses')}
          />
        </div>
        <div>
          <DistributionChart
            title={t('pages.reportsPage.charts.expensesByCategory')}
            data={categoryDistribution}
            total={totalExpenses}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportsPage;
