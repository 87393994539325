export const labelsPage = {
  title: 'Labels',
  subtitle: 'Organize your transactions with labels',
  noLabels: 'No labels found',
  new: 'New Label',
  filters: {
    color: 'Color',
    type: 'Label Type'
  },
  labelTypes: {
    transaction: 'Transaction',
    budget: 'Budget',
    wallet: 'Wallet'
  },
  actions: {
    edit: 'Edit',
    delete: 'Delete',
    assign: 'Assign Label'
  },
  details: {
    transactions: 'Transactions',
    noTransactions: 'No transactions found',
    usageCount: 'transactions'
  },
  validation: {
    nameRequired: 'Label name is required',
    colorRequired: 'Label color is required'
  },
  colorPicker: {
    title: 'Select Label Color',
    preset: 'Preset Colors',
    custom: 'Custom Color'
  }
};
