export const categoriesPage = {
  title: 'Kategoriler',
  subtitle: 'İşlem kategorilerinizi yönetin',
  noCategories: 'Hiç kategori bulunamadı',
  addCategoryButton: 'Kategori Ekle',
  filters: {
    type: 'Kategori Türü',
    parent: 'Ana Kategori'
  },
  categoryTypes: {
    expense: 'Gider Kategorileri',
    income: 'Gelir Kategorileri',
    neutral: 'Nötr'
  },
  actions: {
    edit: 'Düzenle',
    delete: 'Sil',
    deleteSelected: 'Seçilenleri Sil',
    merge: 'Kategorileri Birleştir',
    selectTarget: 'Hedef kategori seçin'
  },
  details: {
    totalSpent: 'Toplam Harcama',
    transactions: 'işlem',
    transactionsIn: 'cüzdanda',
    noTransactions: 'Hiç işlem bulunamadı'
  },
  icons: {
    select: 'İkon Seç',
    search: 'İkonları ara'
  },
  validation: {
    nameRequired: 'Kategori adı zorunludur',
    typeRequired: 'Kategori türü zorunludur'
  }
};
