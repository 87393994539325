import React, { useState } from 'react';
import { Plus, Search, Calendar } from 'lucide-react';
import { useSubscriptions } from '../context/SubscriptionContext';
import { useCreditCards } from '../context/CreditCardContext';
import { useWallets } from '../context/WalletContext';
import { useLanguage } from '../context/LanguageContext';
import { useAuth } from '../context/AuthContext';
import SubscriptionModal from '../components/subscriptions/SubscriptionModal';
import UpcomingSubscriptions from '../components/subscriptions/UpcomingSubscriptions';
import SubscriptionCard from '../components/subscriptions/SubscriptionCard';
import { Subscription } from '../types/subscription';

const SubscriptionsPage = () => {
  const { subscriptions, addSubscription, updateSubscription } = useSubscriptions();
  const { creditCards } = useCreditCards();
  const { wallets } = useWallets();
  const { t } = useLanguage();
  const { user } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [editingSubscription, setEditingSubscription] = useState<Subscription | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Group subscriptions by currency and calculate monthly spending for each currency
  const monthlySpendingByCurrency = subscriptions.reduce((acc, sub) => {
    const monthlyAmount = sub.billingCycle === 'yearly' 
      ? sub.amount / 12 
      : sub.amount;
    
    if (!acc[sub.currency]) {
      acc[sub.currency] = 0;
    }
    acc[sub.currency] += monthlyAmount;
    return acc;
  }, {} as Record<string, number>);

  const filteredSubscriptions = subscriptions.filter(sub =>
    sub.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    sub.description?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleAddSubscription = async (data: Omit<Subscription, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => {
    if (!user) return;
    setIsSubmitting(true);
    setError(null);

    try {
      const timestamp = new Date().toISOString();
      const completeData = {
        ...data,
        userId: user.id,
        createdAt: timestamp,
        updatedAt: timestamp
      };
      await addSubscription(completeData);
      setIsModalOpen(false);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to add subscription');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditSubscription = async (data: Omit<Subscription, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => {
    if (!editingSubscription) return;
    setIsSubmitting(true);
    setError(null);

    try {
      const updatedData = {
        ...data,
        userId: editingSubscription.userId,
        createdAt: editingSubscription.createdAt,
        updatedAt: new Date().toISOString()
      };
      await updateSubscription(editingSubscription.id, updatedData);
      setEditingSubscription(null);
      setIsModalOpen(false);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update subscription');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-8">
      <div className="mb-6">
        <div className="flex items-center justify-between mb-4">
          <div>
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
              {t('pages.subscriptionsPage.title')}
            </h1>
            <p className="text-gray-600 dark:text-gray-400">
              {t('pages.subscriptionsPage.subtitle')}
            </p>
          </div>
          <button
            onClick={() => {
              setEditingSubscription(null);
              setError(null);
              setIsModalOpen(true);
            }}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            {t('pages.subscriptionsPage.addSubscriptionButton')}
          </button>
        </div>

        {error && (
          <div className="mb-4 p-4 text-red-700 bg-red-100 rounded-lg">
            {error}
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          {Object.entries(monthlySpendingByCurrency).map(([currency, amount]) => (
            <div key={currency} className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 p-6">
              <div className="flex items-center gap-3 mb-2">
                <div className="p-2 bg-indigo-100 dark:bg-indigo-900/50 rounded-lg">
                  <Calendar className="h-5 w-5 text-indigo-600 dark:text-indigo-400" />
                </div>
                <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  {t('pages.subscriptionsPage.monthlySpending')} ({currency})
                </h3>
              </div>
              <p className="text-2xl font-bold text-gray-900 dark:text-white">
                {amount.toLocaleString('en-US', {
                  style: 'currency',
                  currency
                })}
              </p>
            </div>
          ))}
        </div>

        <UpcomingSubscriptions />

        <div className="relative mb-6">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
          <input
            type="text"
            placeholder={t('pages.subscriptionsPage.searchPlaceholder')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredSubscriptions.map(subscription => (
          <SubscriptionCard
            key={subscription.id}
            subscription={subscription}
            onEdit={() => {
              setEditingSubscription(subscription);
              setError(null);
              setIsModalOpen(true);
            }}
          />
        ))}
      </div>

      <SubscriptionModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditingSubscription(null);
          setError(null);
        }}
        onSubmit={editingSubscription ? handleEditSubscription : handleAddSubscription}
        initialData={editingSubscription || undefined}
        isSubmitting={isSubmitting}
        error={error}
      />
    </div>
  );
};

export default SubscriptionsPage;
