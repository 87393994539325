export const accountPage = {
  title: 'Hesap Ayarları',
  personalInfo: 'Kişisel Bilgiler',
  name: 'Ad Soyad',
  email: 'E-posta',
  language: 'Dil',
  currency: 'Para Birimi',
  theme: 'Tema',
  notifications: 'Bildirimler',
  security: 'Güvenlik',
  changePassword: 'Şifre Değiştir',
  currentPassword: 'Mevcut Şifre',
  newPassword: 'Yeni Şifre',
  confirmNewPassword: 'Yeni Şifreyi Onayla',
  updateButton: 'Profili Güncelle',
  photo: {
    upload: 'Fotoğraf Yükle',
    remove: 'Fotoğrafı Kaldır'
  },
  errors: {
    nameRequired: 'Ad soyad zorunludur',
    emailRequired: 'E-posta zorunludur',
    emailInvalid: 'Lütfen geçerli bir e-posta adresi girin',
    currentPasswordRequired: 'Mevcut şifre zorunludur',
    newPasswordRequired: 'Yeni şifre zorunludur',
    newPasswordTooShort: 'Yeni şifre en az 8 karakter olmalıdır',
    passwordMismatch: 'Yeni şifreler eşleşmiyor'
  },
  success: {
    profileUpdated: 'Profil başarıyla güncellendi',
    passwordChanged: 'Şifre başarıyla değiştirildi'
  },
  languageOptions: {
    english: 'İngilizce',
    turkish: 'Türkçe'
  },
  themeOptions: {
    light: 'Açık',
    dark: 'Koyu',
    system: 'Sistem Varsayılanı'
  },
  currencyOptions: {
    usd: 'USD - Amerikan Doları',
    eur: 'EUR - Euro',
    gbp: 'GBP - İngiliz Sterlini',
    try: 'TRY - Türk Lirası',
    jpy: 'JPY - Japon Yeni',
    cny: 'CNY - Çin Yuanı'
  }
};
