import en from './en';
import tr from './tr';

export interface TransactionsPageTranslations {
  title: string;
  subtitle: string;
  new: string;
  noTransactions: string;
}

export const translations = {
  en,
  tr
} as const;

export const getTranslations = (language: 'en' | 'tr' = 'en') => {
  return translations[language];
};
