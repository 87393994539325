import React from 'react';
import { Mail, Loader } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
import { getAuthTranslations } from '../locales';

const VerifyEmailPage: React.FC = () => {
  const { resendVerificationEmail, isLoading, error, user } = useAuth();
  const { language } = useLanguage();
  const auth = getAuthTranslations(language);

  const handleResendEmail = async () => {
    try {
      await resendVerificationEmail();
    } catch (err) {
      console.error('Failed to resend verification email:', err);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex justify-center">
          <Mail className="h-12 w-12 text-indigo-600" />
        </div>
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
          {auth.verifyEmail.title}
        </h2>
        <div className="mt-8 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <p className="text-center text-sm text-gray-600 mb-6">
            {auth.verifyEmail.description}
          </p>
          {user && !user.emailVerified && (
            <div className="flex justify-center">
              <button
                onClick={handleResendEmail}
                disabled={isLoading}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
              >
                {isLoading ? (
                  <span className="flex items-center">
                    <Loader className="animate-spin -ml-1 mr-2 h-4 w-4" />
                    {auth.verifyEmail.sending}
                  </span>
                ) : (
                  auth.verifyEmail.resendButton
                )}
              </button>
            </div>
          )}
          {error && (
            <div className="mt-4 p-3 bg-red-50 text-red-700 rounded-md text-sm text-center">
              {error}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailPage;
