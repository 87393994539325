import React, { createContext, useContext, useState } from 'react';

interface Settings {
  siteName: string;
  siteDescription: string;
  defaultCurrency: string;
  defaultLanguage: string;
  smtpHost: string;
  smtpPort: number;
  smtpUsername: string;
  smtpPassword: string;
  fromEmail: string;
  fromName: string;
  apiUrl: string;
  uploadSizeLimit: number;
  allowedFileTypes: string;
  notifications: {
    email: { [key: string]: boolean };
    push: { [key: string]: boolean };
    inApp: { [key: string]: boolean };
  };
  security: {
    passwordRequirements: {
      minLength: boolean;
      requireUppercase: boolean;
      requireLowercase: boolean;
      requireNumbers: boolean;
      requireSpecialChars: boolean;
    };
    sessionTimeout: number;
    maxLoginAttempts: number;
  };
}

interface AdminContextType {
  settings: Settings;
  updateSettings: (updates: Partial<Settings>) => void;
  stats: {
    totalUsers: number;
    activeUsers: number;
    totalRevenue: number;
    activeSubscriptions: number;
  };
}

const defaultSettings: Settings = {
  siteName: 'CostCaddy',
  siteDescription: 'Personal Finance Management System',
  defaultCurrency: 'USD',
  defaultLanguage: 'en',
  smtpHost: '',
  smtpPort: 587,
  smtpUsername: '',
  smtpPassword: '',
  fromEmail: 'noreply@costcaddy.com',
  fromName: 'CostCaddy',
  apiUrl: '',
  uploadSizeLimit: 10,
  allowedFileTypes: 'jpg,png,pdf',
  notifications: {
    email: {
      welcome: true,
      transaction: true,
      subscription: true,
      security: true
    },
    push: {
      welcome: true,
      transaction: true,
      subscription: true,
      security: true
    },
    inApp: {
      welcome: true,
      transaction: true,
      subscription: true,
      security: true
    }
  },
  security: {
    passwordRequirements: {
      minLength: true,
      requireUppercase: true,
      requireLowercase: true,
      requireNumbers: true,
      requireSpecialChars: true
    },
    sessionTimeout: 30,
    maxLoginAttempts: 5
  }
};

const AdminContext = createContext<AdminContextType | undefined>(undefined);

export const useAdmin = () => {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error('useAdmin must be used within AdminProvider');
  }
  return context;
};

export const AdminProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [settings, setSettings] = useState<Settings>(defaultSettings);
  const [stats] = useState({
    totalUsers: 1250,
    activeUsers: 850,
    totalRevenue: 25000,
    activeSubscriptions: 450
  });

  const updateSettings = (updates: Partial<Settings>) => {
    setSettings(current => ({
      ...current,
      ...updates
    }));
  };

  return (
    <AdminContext.Provider value={{ settings, updateSettings, stats }}>
      {children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
