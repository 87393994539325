export const budgetDetailsPage = {
  title: 'Bütçe Detayları',
  overview: 'Genel Bakış',
  totalBudget: 'Toplam Bütçe',
  spent: 'Harcanan',
  remaining: 'Kalan',
  transactions: 'İşlemler',
  noTransactions: 'İşlem bulunamadı',
  categories: 'Kategoriler',
  noCategoriesFound: 'Kategori bulunamadı',
  filters: {
    dateRange: 'Tarih Aralığı',
    category: 'Kategori',
    type: 'İşlem Türü'
  },
  transactionTypes: {
    expense: 'Gider',
    income: 'Gelir'
  },
  budgetStatus: {
    onTrack: 'Yolunda',
    overBudget: 'Bütçe Aşıldı',
    nearingLimit: 'Limite Yaklaşıldı'
  },
  actions: {
    editBudget: 'Bütçeyi Düzenle',
    deleteBudget: 'Bütçeyi Sil',
    addTransaction: 'İşlem Ekle'
  },
  insights: {
    title: 'Bütçe Öngörüleri',
    topCategories: 'En Çok Harcama Yapılan Kategoriler',
    monthlyTrend: 'Aylık Harcama Trendi'
  },
  wallets: 'Cüzdanlar',
  allWallets: 'Tüm cüzdanlar',
  allCategories: 'Tüm kategoriler'
};
