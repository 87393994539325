import React, { useState } from 'react';
import { Plus, Pencil, Trash2 } from 'lucide-react';
import { useLabels } from '../context/LabelContext';
import { useLanguage } from '../context/LanguageContext';
import LabelModal from '../components/labels/LabelModal';

const LabelsPage: React.FC = () => {
  const { labels, deleteLabel } = useLabels();
  const { t } = useLanguage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLabelId, setSelectedLabelId] = useState<string | null>(null);

  const handleEdit = (labelId: string) => {
    setSelectedLabelId(labelId);
    setIsModalOpen(true);
  };

  const handleDelete = async (labelId: string) => {
    if (window.confirm(t('common.confirmDelete'))) {
      try {
        await deleteLabel(labelId);
      } catch (error) {
        console.error('Error deleting label:', error);
      }
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedLabelId(null);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            {t('pages.labelsPage.title')}
          </h1>
          <p className="text-gray-600 dark:text-gray-400">
            {t('pages.labelsPage.subtitle')}
          </p>
        </div>
        <button
          onClick={() => setIsModalOpen(true)}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <Plus className="h-4 w-4 mr-2" />
          {t('pages.labelsPage.new')}
        </button>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
        <div className="divide-y divide-gray-200 dark:divide-gray-700">
          {labels.length === 0 ? (
            <div className="p-6 text-center text-gray-500 dark:text-gray-400">
              {t('pages.labelsPage.noLabels')}
            </div>
          ) : (
            labels.map(label => (
              <div
                key={label.id}
                className="flex items-center justify-between p-4 hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                <div className="flex items-center space-x-3">
                  <div className={`w-4 h-4 rounded-full bg-${label.color}-500`} />
                  <span className="text-gray-900 dark:text-white">{label.name}</span>
                  {label.transactionCount > 0 && (
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      ({label.transactionCount} {t('pages.labelsPage.details.usageCount')})
                    </span>
                  )}
                </div>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => handleEdit(label.id)}
                    className="p-1 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                  >
                    <Pencil className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => handleDelete(label.id)}
                    className="p-1 text-gray-400 hover:text-red-500"
                    disabled={label.transactionCount > 0}
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <LabelModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        labelId={selectedLabelId}
      />
    </div>
  );
};

export default LabelsPage;
